import { useDataViewContext } from "../DatavViewContext";
import { IDataViewComponentProps } from "../IDataViewComponentProps";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { Loading } from "../../Loading";
import { Box, Paper } from "@mui/material";
import Card from "@mui/material/Card";
import SimpleImageViewer from "../../../SimpleImageViewer";
import * as api from "@crochik/pi-api";

interface IImageGalleryViewHookProps extends IDataViewComponentProps {
    records: string[];
}

function ImageGalleryViewComponentHook({ onClick, onObjectMenu }: IImageGalleryViewHookProps) {
    const viewContext = useDataViewContext();
    const { dataView } = viewContext;
    const { response, records } = dataView;
    const [ isOpen, open ] = useState(false);
    const [ selectedIndex, setSelectedIndex ] = useState(0);
    const [ images, setImages ] = useState<string[]>();

    const options = dataView.options as api.ImageGalleryViewOptions;

    const onClickImage = (index) =>  () =>{
        setSelectedIndex(index);
        open(true);
    }

    const closeViewer = () => {
        open(false);
    }

    const renderCell = useCallback((index: number, id: string) => {
        const record = dataView.get(id);
        return (
            <Card elevation={2} style={{ padding: 12, margin: 6 }}>
                <img src={record[options.thumbnailUrl ?? options.imageUrl!]} onClick={onClickImage(index)} />
            </Card>
        );
    }, [dataView]);

    useEffect(() => {
        if (!isOpen) {
            setImages(undefined);
            return;
        }
        const images: string[] = records.map(x=>{
            const record = dataView.get(x);
            return record[options.imageUrl!];
        });
        setImages(images);
        console.log(images);
    }, [isOpen, dataView]);

    return (
        <>
            <Box
                sx={{
                    overflow: "auto",
                    height: "100%",
                    padding: "12px",
                    backgroundColor: "#cccccc",
                }}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    width: "100%"
                }}>
                    {records.map((x,i) => renderCell(i, x))}
                </div>
            </Box>
            {
                isOpen && !!images && images.length>0 && (
                    <SimpleImageViewer src={images} onClose={closeViewer} closeOnClickOutside={true} currentIndex={selectedIndex}/>
                )
            }
        </>
    );
}

interface State {
    filterElement?: Element;
    filterField?: string;
}

@observer
export class ImageGalleryViewComponent extends React.Component<IDataViewComponentProps, State> {

    render() {
        const { view, onClick, onObjectMenu } = this.props;
        const { records } = view;
        if (!records) return <Loading />;

        return <ImageGalleryViewComponentHook records={records} onClick={onClick} view={view}
                                              onObjectMenu={onObjectMenu} />;
    }
}
