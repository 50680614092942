import * as chartjs from "chart.js";
import * as React from "react";
import * as Chart from "../chart/BarChart";

import { LinearProgress, Paper } from "@mui/material";
import { IReactionDisposer, reaction } from "mobx";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import { DataView } from "src/pi/ui/DataView";
import { DataGridProps } from "./DataGridProps";
import { DataViewContainer } from "./DataViewContainer";
import { DataViewContext } from "./DatavViewContext";

export interface IOnClick {
    start: moment.Moment;
    interval: "hour" | "day";
    label: string;
}

interface Props {
    dataView?: DataView;
    perHour?: boolean;
    height?: number;
}

interface State {
    data?: chartjs.ChartData;
    cols?: moment.Moment[];
}

export class DataViewBarChart extends React.Component<Props, State> {
    disposer?: IReactionDisposer;

    constructor(props: any) {
        super(props);

        this.state = {};
    }

    componentWillUnmount() {
        if (this.disposer) this.disposer();
    }

    async componentDidMount() {
        const { dataView } = this.props;
        if (!dataView) return;

        this.disposer = reaction(
            () => dataView.response,
            () => {
                this.prepare();
            }
        );

        this.prepare();
    }

    prepare() {
        const state = {
            data: {
                labels: new Array<string>(),
                datasets: new Array<chartjs.ChartDataSets>(),
            },
            cols: Array<moment.Moment>(),
        };

        const { dataView } = this.props;
        if (!dataView) {
            this.setState(state);
            return;
        }

        const { response } = dataView;
        if (!response) {
            this.setState(state);
            return;
        }

        const { data } = state;
        const { view, result } = response;
        if (!view || !result) {
            console.error("failed to load");
            this.setState(state);
            return;
        }

        const keyField = view.keyField ?? "_id";
        var min: moment.Moment | undefined;
        var max: moment.Moment | undefined;
        result.forEach((r) => {
            var dateStr = r[keyField] as string;
            if (dateStr.length > 10) {
                // remove time/timezone info
                dateStr = dateStr.substr(0, 10);
            }

            const value = moment(dateStr);
            if (!min || min > value) min = value;
            if (!max || max < value) max = value;
        });

        if (!min || !max) {
            this.setState(state);
            return;
        }

        const { perHour } = this.props;
        const start = min.clone();
        const end = max.clone();
        const count = max.diff(min, perHour ? "hour" : "day");

        if (perHour) {
            for (var hour = start.clone(); hour < end; hour.add(1, "hour")) {
                data.labels.push(`${hour.format("ddd")}, ${hour.format("hh A")}`);
                state.cols.push(hour.clone());
            }
        } else {
            for (var date = start.clone(); date < end; date.add(1, "day")) {
                data.labels.push(`${date.format("ddd")}, ${date.format("MM-DD")}`);
                state.cols.push(date.clone());
            }
        }

        const fields = view.fields?.filter((x) => x.name !== keyField) ?? [];
        const datasetDict: { [key: string]: chartjs.ChartDataSets } = {};
        fields.forEach((x, i) => {
            const name = x.name ?? i.toString();
            const color = Chart.colors[i];
            const dataSet = Chart.initDataSet(name, Chart.getStyle(color));
            data.datasets.push(dataSet);
            datasetDict[name] = dataSet;

            if (!dataSet?.data) return;
            for (var c = 0; c < count; c++) dataSet.data[c] = 0;
        });

        fields.forEach((x, i) => {
            const name = x.name ?? i.toString();
            const ds = datasetDict[name];
            result.forEach((r) => {
                var dateStr = r[keyField] as string;
                if (dateStr.length > 10) {
                    // remove time/timezone info
                    dateStr = dateStr.substr(0, 10);
                }

                const index = moment(dateStr).diff(start, perHour ? "hours" : "days");
                const value = r[name];
                if (!ds.data) return;

                ds.data[index] = value || 0;
            });
        });

        this.setState(state);
    }

    // onElementsClick = (e: IElementClick[]) => {
    //     if (!this.state.cols || !this.props.OnElementClick) return;

    //     this.props.OnElementClick({
    //         label: e[0].label,
    //         interval: this.props.perHour ? 'hour' : 'day',
    //         start: this.state.cols[e[0].index]
    //     });
    // }

    render() {
        const { height } = this.props;
        const { data } = this.state;

        if (!data)
            return (
                <div>
                    <LinearProgress style={{ width: "100%" }} />
                </div>
            );

        return (
            <Paper
                sx={{
                    overflow: "auto",
                    height: "calc(100% - 64px)",
                    padding: "12px",
                    marginTop: "1px",
                }}
            >
                <Bar
                    data={data}
                    width={100}
                    height={height || 250}
                    // onElementsClick={this.onElementsClick}
                    options={{
                        maintainAspectRatio: false,
                        scales: {
                            xAxes: [
                                {
                                    stacked: true,
                                },
                            ],
                            yAxes: [
                                {
                                    stacked: true,
                                },
                            ],
                        },
                        tooltips: {
                            mode: "index",
                            intersect: false,
                        },
                    }}
                />
            </Paper>
        );

        // return (
        //     <Chart.BarChart
        //         data={data}
        //         // isSelected={isSelected}
        //         title={title}
        //         // onClick={onClick}
        //         height={height}
        //         // onElementsClick={this.onElementsClick}
        //     />
        // );
    }
}

export const DataViewBarChartComponent = (props: DataGridProps) => (
    <DataViewContainer {...props}>
        <DataViewContext.Consumer>{(context) => context && <DataViewBarChart dataView={context.dataView} />}</DataViewContext.Consumer>
    </DataViewContainer>
);
