import Card from "@mui/material/Card";

import ActionButton from "src/pi/ui/material/ActionButton";

import { Alert, Box, Container } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { ISlimUser, Session } from "src/app/Session";
import LoginService from "src/app/services/LoginService";
import { Loading } from "src/pi/ui/material/Loading";
import Button from "@mui/material/Button";

const idpButton = {
    Microsoft: <ActionButton title="Office 365" action="loginOffice365" context="app" variant="contained"
                             color="secondary" />,
    Salesforce: <ActionButton title="Salesforce" action="loginSalesforce" context="app" variant="contained"
                              color="primary" />,
    Google: <ActionButton title="Google" action="loginGoogle" context="app" variant="contained" color="secondary" />
};

async function loadContent() {
    const host = document.location.hostname;
    let resp = await fetch(`client/${host}/welcome.html`);
    if (resp.status===404) {
        resp = await fetch(`https://cfg.bootstrapp.cloud/client/${host}/welcome.html`);
    }

    const html = await resp.text();

    return html;
}

export default function Welcome() {

    const { bootstrap } = Session.get();
    const { idps } = bootstrap;

    const [message, setMessage] = useState<string>();
    const [error, setError] = useState<string>();
    const [tryRefresh, setTryRefresh] = useState<boolean>(false);
    const [refreshing, setRefreshing] = useState<boolean>(false);

    useEffect(() => {
        const service = LoginService();

        service.canRefresh()
            .then((canRefresh) => {
                setTryRefresh(canRefresh);
            });

    }, []);

    useEffect(() => {
        loadContent()
            .then((html) => {
                setMessage(html);
            })
            .catch(() => {
                setError("Failed to load configuration");
            });

        if (!idps || idps.length < 1) {
            console.log("no IDPs, sign in anonymously");
            LoginService().signIn("Anonymous");
        }
    }, [idps]);

    const onResumeSession = () => {
        setRefreshing(true);

        LoginService().refreshToken()
            .then((user)=>{
                setRefreshing(false);
                if (!user) {
                    setTryRefresh(false);
                    return;
                }
                console.log("refreshed token successfully, reload");
                document.location.reload();

            })
            .catch((e)=>{
                setRefreshing(false);
                console.error("Failed to refresh", e);
                setTryRefresh(false);
            })
    };

    const Body = useCallback(() => {
        if (tryRefresh) {
            return (
                <Card style={{ padding: 24 }}>
                    <Alert severity="info">Your Session has expired.</Alert>
                    <br />
                    <Button onClick={onResumeSession} disabled={refreshing}>Resume</Button>
                </Card>
            );
        }

        if (!message) {
            return <Loading />;
        }

        const html = {
            __html: message
        };

        return (
            <Card style={{ padding: 24 }}>
                <div dangerouslySetInnerHTML={html} />
                {idps &&
                    <div style={{ padding: 12, margin: 12, display: "flex", justifyContent: "center" }}>
                        {idps.map((x, i) => (
                            <div key={`idp-${i}`} style={{ marginLeft: 6, marginRight: 6 }}>
                                {idpButton[x]}
                            </div>
                        ))}
                    </div>
                }
            </Card>
        );

    }, [message, tryRefresh, refreshing]);

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Box
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                padding: 24,
                fontFamily: "Roboto, sans-serif",
                position: "fixed",
                margin: 0,
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                overflow: "hidden",
                backgroundColor: "#eeeeee"
                // alignItems: 'center',
                // justifyContent: 'center',
                // border: '1px solid red',
            }}
        >
            <Container maxWidth="sm">
                <Body />
            </Container>
        </Box>
    );
}