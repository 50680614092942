import * as React from "react";

import { CircularProgress, FilterOptionsState, FormControl, InputLabel, TextField } from "@mui/material";
import Autocomplete, { AutocompleteRenderOptionState } /*, { createFilterOptions } */ from "@mui/material/Autocomplete";

import { Action, IField } from "../../../../context/IForm";
import { Form } from "../../../Form";
import { FieldProps } from "../FieldProps";

import { Operator } from "@crochik/pi-api";
import App, { IFormDialog } from "src/pi/application/App";
import { IReferenceFieldOptions } from "../../../../context/IForm";
import DataService, { Condition, IDataFormActionResponse, IReferenceValue } from "../../../../services/DataService";

interface IState {
    rows?: IReferenceValue[];
    error?: string;
    open: boolean;
    loading: boolean;
    noneFound: boolean;

    selected?: IReferenceValue;
    inputValue?: string;
}

interface IProps extends FieldProps {
    form?: Form;
}

export function getAutoCompleteCriteria(field: IField, form?: Form, value?: string): Condition[] {
    const options = field.options as IReferenceFieldOptions;
    const { criteria } = options;

    const qcriteria = criteria ? [...criteria] : [];

    var addOrUpdate = (key: string, value?: any) => {
        if (!value) return;
        var existing = qcriteria.find((x) => x.fieldName === key);
        if (existing) {
            existing.operator = Operator.Eq;
            existing.value = value;
        } else {
            qcriteria.push({
                fieldName: key,
                operator: Operator.Eq,
                value
            });
        }
    };

    addOrUpdate("#autocomplete", value);

    return form?.updateCriteria(qcriteria) ?? qcriteria;
}

function addAdditionalItems(field: IField, rows: IReferenceValue[], filter?: string) {
    const options = field.options as IReferenceFieldOptions;
    const { items } = options;

    filter = filter?.toLowerCase();

    if (items && Object.keys(items).length > 0) {
        for (var option of Object.keys(items)) {
            const value = items[option];
            if (!!filter && value.toLowerCase().indexOf(filter) < 0) continue;

            const refValue: IReferenceValue = {
                id: option,
                value
            };
            rows.push(refValue);
        }
    }
}

export class ReferenceField extends React.Component<IProps, IState> {
    requestIndex: number = 0;
    divRef?: React.RefObject<{}>;

    constructor(props: FieldProps) {
        super(props);

        this.state = {
            open: false,
            loading: false,
            noneFound: false
        };

        this.divRef = React.createRef();
    }

    async init() {
        const { field, form, value } = this.props;

        console.log("init", value);

        const options = field.options as IReferenceFieldOptions;
        if (!options) {
            console.error("ReferenceField", "Missing required options");
            this.setState({
                error: "Missing required options"
            });
            return;
        }

        if (form?.isDesigning) {
            this.setState({ rows: [] });
            return;
        }

        if (value) {
            const selected = await this.fetchCurrentValueAsync(value);
            this.setState({ selected });

        } else {
            this.setState({ rows: [] });
        }
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (this.props.value !== prevProps.value) {
            this.init();
        }
    }

    onChange = (event: any) => {
        const { field } = this.props;

        var value = event.target.value;

        if (this.props.onChange) {
            this.props.onChange(field, value);
        }
    };

    onDialogAction = async (dialog: IFormDialog, action?: Action, lastResult?: IDataFormActionResponse) => {
        if (action?.name === "#close" && lastResult?.success) {
            console.log("close dialog after success", dialog, action, lastResult);
            if (lastResult.action === "Add") {
                if (!lastResult.ids || lastResult.ids.length !== 1) {
                    console.error("Missing created object id");
                    return false;
                }

                await this.fetchCurrentValueAsync(lastResult.ids[0]);

                const { onChange, field } = this.props;
                onChange?.(field, lastResult.ids[0]);
            }
        }

        return false;
    };

    onValueChanged = (event: React.ChangeEvent<{}>, value: any) => {
        const { form, field } = this.props;
        const { noneFound } = this.state;

        if (noneFound && value) {
            const options = field.options as IReferenceFieldOptions;
            const objectType = form?.getObjectTypeForReferenceField(options);
            if (objectType && !objectType.startsWith("/")) {
                this.setState({ open: false });

                const url = `/api/v1/CustomObject/${objectType}/Add`;
                const seedValues: { [key: string]: any } = {
                    Name: value.id
                };

                if (options.criteria) {
                    // seed form with criteria
                    options.criteria.forEach(x => {
                        if (!x.fieldName) return;
                        if (x.operator && x.operator !== Operator.Eq) return;
                        seedValues[x.fieldName] = x.value;
                    });
                }

                App()
                    .dataFormAsync(url, undefined, this.onDialogAction, seedValues)
                    .catch((reason) => {
                        console.error("Failed to start add form", reason);
                    });
            }
            return;
        }

        this.setState({
            open: false
        });

        if (this.props.onChange) {
            this.setState({ selected: value, inputValue: undefined });
            this.props.onChange(field, value?.id);
        }
    };

    onInputChanged = async (event: React.ChangeEvent<{}>, value: string, reason: string) => {
        switch (reason) {
            case "input":
                this.setState({ inputValue: value });
                await this.search(value);
                break;
        }
    };

    async search(value: string) {
        const { form, field } = this.props;
        const qcriteria = getAutoCompleteCriteria(field, form, value);
        return await this.fetch(qcriteria);
    }

    async fetch(criteria: Condition[]): Promise<IReferenceValue[] | undefined> {
        const { form, field } = this.props;
        const options = field.options as IReferenceFieldOptions;

        const objectType = form?.getObjectTypeForReferenceField(options);
        if (!objectType) {
            this.setState({
                error: "Bad config",
                loading: false
            });
            return;
        }

        try {
            const index = ++this.requestIndex;

            this.setState({ rows: [], loading: true });

            var rows = await DataService().lookupAsync(objectType, {
                criteria,
                lookupField: options.foreignFieldName
            });

            if (this.requestIndex !== index) {
                console.debug("fetch, drop", this.requestIndex, index);
                return;
            }

            addAdditionalItems(field, rows);
            const noneFound = rows.length === 0 && !options.allowUnknown;

            // make sure current selected is in the list 
            if (this.state.selected && !rows?.find(x => x.id === this.state.selected?.id)) {
                rows.push(this.state.selected);
            }

            if (options.allowUnknown) {
                const value = criteria.find(x => x.fieldName === "#id" || x.fieldName === "#autocomplete");
                if (value?.value) {
                    if (!rows?.find(x => x.id === value.value)) {
                        console.log(`${value.value} is not in the options`);
                        rows.push({ id: value.value, value: value.value.toString() });
                    }
                }
            }

            this.setState({
                rows,
                loading: false,
                noneFound
            });

            return rows;

        } catch (ex) {
            const reason = ex as any;
            this.setState({
                error: reason,
                loading: false
            });

            return undefined;
        }
    }

    get inputLabelProps() {
        return {
            required: this.props.field.isRequired,
            error: this.props.error ? true : false,
            htmlFor: this.props.field.name!,
            shrink: true
        };
    }

    render() {
        const { style, field } = this.props;
        const options = field.options as IReferenceFieldOptions;

        return (
            <div
                style={{
                    display: "flex",
                    paddingTop: 16,
                    paddingBottom: 8,
                    ...style
                }}
                key={field.name}
            >
                <FormControl style={{ width: "100%" }}>
                    {!options?.autoComplete && (
                        <>
                            <InputLabel {...this.inputLabelProps}>{field.label ?? field.name!}</InputLabel>
                            <br />
                        </>
                    )}
                    {this.renderField()}
                </FormControl>
            </div>
        );
    }

    async fetchCurrentValueAsync(value?: string): Promise<IReferenceValue | undefined> {
        const { field, form } = this.props;
        const options = field.options as IReferenceFieldOptions;

        const criteria = [
            ...(options?.criteria ?? []),
            {
                fieldName: "#id",
                operator: Operator.Eq,
                value
            }
        ];

        const rows = await this.fetch(form?.updateCriteria(criteria) ?? criteria);
        const selected = rows?.find(x => x.id === value);
        return selected;
    }

    onOpen = async () => {
        // const { value } = this.props;
        // const { rows } = this.state;
        // const selected = value && rows ? rows.find((x) => x.id === value) : null;
        const { inputValue } = this.state;

        // TODO: wont' work for the first letter typed while closed
        // ...
        const { form, field } = this.props;
        const criteria = getAutoCompleteCriteria(field, form, inputValue ?? "");

        this.setState({ open: true });
        return await this.fetch(criteria);
    };

    // onOpen = () => {
    //     this.setState({ open: true });
    // }

    onClose = () => {
        this.setState({ open: false, inputValue: undefined }, this.selectText); // 
    };

    selectText() {
        if (this.divRef && this.divRef.current) {
            const input = (this.divRef.current as HTMLElement).querySelector("INPUT") as HTMLInputElement;
            if (input) input.select();
        }
    }

    filter(options: IReferenceValue[], state: FilterOptionsState<IReferenceValue>): IReferenceValue[] {
        const { value } = this.props;

        var inputValue = state.inputValue.toLowerCase();
        return options.filter((x) => {
            if (!inputValue || inputValue.length === 0) return true;
            if (x.value?.toLowerCase().includes(inputValue)) return true;
            return false;
        });
    }

    filterOptions = (options, params) => {
        const { loading, noneFound } = this.state;
        const { inputValue } = params;

        // no need to filter client side?
        const filtered = options; //  this.filter(options, params);

        if (!loading && noneFound && !!inputValue && inputValue.length > 0) {
            // none found 
            const { field } = this.props;
            const options = field.options as IReferenceFieldOptions;
            const newAction = options.actions ? options.actions.find(x => (x.action ?? x.name) === "#new") : undefined;
            if (!!newAction) {
                // Suggest the creation of a new value
                filtered.push({
                    id: inputValue,
                    value: `Not found. ${newAction.label ?? `Create "${inputValue}"...`}`
                });
            }
        }

        return filtered;
    };

    renderField() {
        const { field, disabled } = this.props;
        const { rows, error, open, loading, noneFound } = this.state;
        if (error) {
            return <span>error</span>;
        }

        if (!rows) {
            return <CircularProgress color="inherit" size={20} />;
        }

        const value = this.props.value || null;

        // const selected = rows?.find((x) => x.id === value);
        const { selected, inputValue } = this.state;

        const noOptionsText = loading ? "Loading..." : noneFound ? "Not found" : "Search...";

        // const filterOptions = createFilterOptions({
        //     ignoreAccents: true,
        //     ignoreCase: true,
        //     matchFrom: 'any',
        //     stringify: option => (option as any).value || '',
        // });

        return (
            <Autocomplete
                ref={this.divRef}
                // filterOptions={filterOptions}
                autoComplete={true}
                autoHighlight={true}
                clearOnEscape={true}
                selectOnFocus={true}
                value={value}
                onChange={this.onValueChanged}
                onInputChange={this.onInputChanged}
                inputValue={(open ? inputValue : selected?.value) ?? ""}
                disabled={disabled}
                loading={loading}
                open={open}
                options={rows || []}
                getOptionLabel={(x) => x.value || (selected ? selected.value : "")}
                isOptionEqualToValue={(o) => o.id === value}
                onOpen={this.onOpen}
                onClose={this.onClose}
                noOptionsText={noOptionsText}
                filterOptions={this.filterOptions}
                renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: IReferenceValue, state: AutocompleteRenderOptionState) => {
                    return (
                        <li {...props}>
                            <span
                                style={{ fontWeight: option.id === field.defaultValue?.toString() ? 600 : undefined }}>{option.value ?? (selected ? selected.value : "")}</span>
                        </li>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={field.label || field.name}
                        variant="filled"
                        required={field.isRequired}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                    />
                )}
            />
        );
    }
}
