import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import { css, StyleSheet } from "aphrodite";

interface IProps {
    src: string[];
    currentIndex?: number;
    backgroundStyle?: CSSProperties;
    imageStyle?: CSSProperties;
    disableScroll?: boolean;
    closeOnClickOutside?: boolean;
    onClose?: () => void;
    closeComponent?: JSX.Element;
    leftArrowComponent?: JSX.Element;
    rightArrowComponent?: JSX.Element;
}

// https://github.com/specter256/react-simple-image-viewer
const ReactSimpleImageViewer = (props: IProps) => {
    const [currentIndex, setCurrentIndex] = useState(props.currentIndex ?? 0);

    const changeImage = useCallback(
        (delta: number) => {
            let nextIndex = (currentIndex + delta) % props.src.length;
            if (nextIndex < 0) nextIndex = props.src.length - 1;
            setCurrentIndex(nextIndex);
        },
        [currentIndex]
    );

    const handleClick = useCallback(
        (event: any) => {
            if (!event.target || !props.closeOnClickOutside) {
                return;
            }

            const checkId = event.target.id === "ReactSimpleImageViewer";
            const checkClass = event.target.classList.contains("react-simple-image-viewer__slide");

            if (checkId || checkClass) {
                event.stopPropagation();
                props.onClose?.();
            }
        },
        [props.onClose]
    );

    const handleKeyDown = useCallback(
        (event: any) => {
            if (event.key === "Escape") {
                props.onClose?.();
            }

            if (["ArrowLeft", "h"].includes(event.key)) {
                changeImage(-1);
            }

            if (["ArrowRight", "l"].includes(event.key)) {
                changeImage(1);
            }
        },
        [props.onClose, changeImage]
    );

    const handleWheel = useCallback(
        (event: any) => {
            if (event.wheelDeltaY > 0) {
                changeImage(-1);
            } else {
                changeImage(1);
            }
        },
        [changeImage]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);

        if (!props.disableScroll) {
            document.addEventListener("wheel", handleWheel);
        }

        return () => {
            document.removeEventListener("keydown", handleKeyDown);

            if (!props.disableScroll) {
                document.removeEventListener("wheel", handleWheel);
            }
        };
    }, [handleKeyDown, handleWheel]);

    return (
        <div
            id="ReactSimpleImageViewer"
            className={`${css(styles.wrapper)} react-simple-image-viewer__modal`}
            onKeyDown={handleKeyDown}
            onClick={handleClick}
            style={props.backgroundStyle}
        >
      <span
          className={`${css(styles.close)} react-simple-image-viewer__close`}
          onClick={() => props.onClose?.()}
      >
        {props.closeComponent || "×"}
      </span>

            {props.src.length > 1 && (
                <span
                    className={`${css(styles.navigation)} ${css(styles.prev)} react-simple-image-viewer__previous`}
                    onClick={() => changeImage(-1)}
                >
          {props.leftArrowComponent || "❮"}
        </span>
            )}

            {props.src.length > 1 && (
                <span
                    className={`${css(styles.navigation)} ${css(styles.next)} react-simple-image-viewer__next`}
                    onClick={() => changeImage(1)}
                >
          {props.rightArrowComponent || "❯"}
        </span>
            )}

            <div
                className={`${css(styles.content)} react-simple-image-viewer__modal-content`}
                onClick={handleClick}
            >
                <div className={`${css(styles.slide)} react-simple-image-viewer__slide`}>
                    <img className={css(styles.image)} src={props.src[currentIndex]} alt="" style={props.imageStyle} />
                </div>
            </div>
        </div>
    );
};

export default ReactSimpleImageViewer;

const styles = StyleSheet.create({
    wrapper: {
        zIndex: 99999,
        display: "flex",
        alignItems: "center",
        position: "fixed",
        padding: "0px 60px 0px 60px",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        boxSizing: "border-box",
        "@media (max-width: 900px)": {
            padding: "0"
        }
    },
    content: {
        margin: "auto",
        padding: 0,
        width: "90%",
        height: "100%",
        maxHeight: "100%",
        textAlign: "center"
    },
    slide: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    image: {
        maxHeight: "100%",
        maxWidth: "100%",
        userSelect: "none",
        "-moz-user-select": "none",
        "-webkit-user-select": "none"
    },
    close: {
        color: "white",
        position: "absolute",
        top: "15px",
        right: "15px",
        fontSize: "40px",
        fontWeight: "bold",
        opacity: 0.2,
        cursor: "pointer",
        ":hover": {
            opacity: 1
        }
    },
    navigation: {
        height: "80%",
        color: "white",
        cursor: "pointer",
        position: "absolute",
        fontSize: "60px",
        lineHeight: "60px",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        opacity: 0.2,
        padding: "0 15px",
        userSelect: "none",
        "-moz-user-select": "none",
        "-webkit-user-select": "none",
        ":hover": {
            opacity: 1
        },
        "@media (hover: none)": {
            ":hover": {
                opacity: 0.2
            }
        }
    },
    prev: {
        left: "0"
    },
    next: {
        right: "0"
    }
});