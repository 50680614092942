import { Card, Link, Typography } from "@mui/material";
import React from "react";
import { CardProps } from ".";
import { IField } from "../../../../context/IForm";
import { IOnClickArgs } from "../DatavViewContext";
import { renderValue } from "../content";

interface DefaultCellProps extends CardProps {
    field: IField;
}

function DefaultCell({ view, onClick, row, field }: DefaultCellProps) {
    const args: IOnClickArgs = {
        row,
        field: field.name,
    };

    const onClickCell = (e: React.MouseEvent) => onClick(e, args);

    let content = renderValue(view, field, row);
    if (!content) return null;

    if (field.options?.linkUrl) {
        content = (
            <Link onClick={onClickCell} underline="hover" color="primary" style={{ cursor: "pointer" }}>
                {content}
            </Link>
        );
    }

    return (
        <div key={field.name}>
            <Typography sx={{ fontSize: 12 }} color="text.secondary">
                {field.label || field.name}
            </Typography>
            <Typography gutterBottom>{content}</Typography>
        </div>
    );
}

export function DefaultCard({ view, row, onClick, id }: CardProps) {
    const { visibleFields } = view;

    const args: IOnClickArgs = {
        row,
    };

    const onClickHandler = (e: React.MouseEvent) => onClick(e, args);

    return (
        <Card
            key={id}
            sx={{
                padding: "12px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                cursor: "pointer",
            }}
            onClick={onClickHandler}
        >
            {visibleFields?.map((field, i) => (
                <DefaultCell view={view} onClick={onClick} id={id} row={row} field={field} />
            ))}
        </Card>
    );
}
