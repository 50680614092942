import { AppointmentTooltip } from "@devexpress/dx-react-scheduler-material-ui";
import EventIcon from '@mui/icons-material/Event';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Typography } from "@mui/material";
import moment from "moment";
import { useDataViewContext } from "../DatavViewContext";
import { ICalendarRow } from "./ICalendarRow";
import { Status } from "./Status";

export function AppointmentTooltipHeaderComponent(props: AppointmentTooltip.HeaderProps) {
    const dataViewContext = useDataViewContext();
    const { onObjectMenu } = dataViewContext;

    const { appointmentData } = props;
    const row = appointmentData?.["_data"] as any as ICalendarRow;
    const status: Status = moment(row.Start).isAfter(moment()) ? "Future" : "InThePast";

    const onShowPopup = (e: React.MouseEvent) => {
        onObjectMenu?.(e.target as HTMLElement, row, "Appointment");
    }

    const isAppointment = row.Type === "Appointment";
    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 12, paddingBottom: 3 }}>
            <EventIcon style={{ marginBottom: 8, marginRight: 8, marginTop: 8 }} />
            <div style={{ cursor: isAppointment ? "pointer" : undefined }}>
                <Typography fontWeight={status === "Future" ? "bold" : "normal"}>{row.Name ?? row.Type}</Typography>
            </div>
            {isAppointment && <IconButton onClick={onShowPopup}><MoreVertIcon /></IconButton>}
        </div>
    );
}
