import { Card, Typography } from "@mui/material";
import React from "react";
import { CardProps } from ".";
import { IOnClickArgs } from "../DatavViewContext";
import { renderValue } from "../content";

export function NotificationCard({ view, row, onClick, id }: CardProps) {
    const args: IOnClickArgs = {
        row,
    };

    const onClickHandler = (e: React.MouseEvent) => onClick(e, args);

    const { visibleFields } = view;
    const nameField = visibleFields.find((x) => x.name === "Name");
    const descriptionField = visibleFields.find((x) => x.name === "Description");
    const createdOnField = visibleFields.find((x) => x.name === "CreatedOn");

    const { ReadOn } = row as any;

    return (
        <Card
            key={id}
            elevation={ReadOn ? 0 : 3}
            sx={{
                padding: "12px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                cursor: "pointer",
            }}
            onClick={onClickHandler}
        >
            {nameField && (
                <Typography sx={{ fontSize: 12 }} color={ReadOn ? "text.secondary" : "primary"}>
                    {renderValue(view, nameField, row)}
                </Typography>
            )}
            {descriptionField && (
                <Typography sx={{}} color="text.primary">
                    {renderValue(view, descriptionField, row)}
                </Typography>
            )}
            {createdOnField && (
                <Typography sx={{ fontSize: 12 }} color="text.secondary">
                    {renderValue(view, createdOnField, row)}
                </Typography>
            )}
        </Card>
    );
}
