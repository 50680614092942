import MoreVertIcon from '@mui/icons-material/MoreVert';

import { AppointmentModel, BaseView, ChangeSet, EditingState, Grouping, GroupingState, IntegratedEditing, IntegratedGrouping, Resource, ResourceInstance, ViewState } from "@devexpress/dx-react-scheduler";
import {
    AppointmentTooltip,
    Appointments,
    CurrentTimeIndicator,
    DateNavigator,
    DragDropProvider,
    GroupingPanel,
    MonthView,
    Resources,
    Scheduler,
    TodayButton,
    Toolbar,
    ViewSwitcher
} from "@devexpress/dx-react-scheduler-material-ui";
import { Alert, IconButton, Paper, Typography, useTheme } from "@mui/material";
import { StyleSheet } from "aphrodite";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import * as api from "@crochik/pi-api";
import App from 'src/pi/application/App';
import { Loading } from "../../Loading";
import { useDataViewContext } from '../DatavViewContext';
import { IDataViewComponentProps } from "../IDataViewComponentProps";
import { AgendaView } from "./AgendaView";
import { AppointmentTooltipComponent } from "./AppointmentTooltipComponent";
import { AppointmentTooltipHeaderComponent } from "./AppointmentTooltipHeaderComponent";
import { CalendarView } from "./CalendarView";
import { ICalendarRow } from "./ICalendarRow";
import { colors } from "./colors";
import { URI } from "../../../../api/URI";

interface AppointmentModelExt extends AppointmentModel {
    userId: string;
    eventType: string;
    _data: ICalendarRow
}

interface IState {
    events: AppointmentModelExt[];
    resources: Resource[];
}

interface ICalendarViewComponentHookProps extends IDataViewComponentProps {
    loadTimestamp?: Date;
    height: number;
}

function CalendarViewComponentHook(props: ICalendarViewComponentHookProps) {
    const { view, loadTimestamp, height } = props; // key
    const options = view.response?.options as api.CalendarViewOptions;
    const { startHour, endHour } = options ?? {};

    const [currentViewName, setViewName] = useState<string>();
    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const [data, setData] = useState<IState>();
    const appointmentId = useRef<string>();
    // const app = useApplicationContext();
    const theme = useTheme();

    useEffect(() => {
        console.log('changed');

        if (view.records.length < 1) {
            setData({
                events: [],
                resources: [],
            });
            return;
        }

        let userCount = 0;
        const users: { [id: string]: number } = {};
        const instances: ResourceInstance[] = [];
        const newData = view.records.map(
            (x) => {
                const row = view.get(x) as ICalendarRow;
                const userId = row.UserId
                if (!(userId in users)) {
                    users[userId] = userCount;
                    instances.push({
                        id: userId,
                        text: row["UserId|Name"] as string,
                        color: colors[userCount++][400],
                    });
                }

                const appointmentModel: AppointmentModelExt = {
                    startDate: row.Start,
                    endDate: row.End,
                    title: row.Name,
                    userId: userId,
                    eventType: row.Type,
                    _data: row
                };

                return appointmentModel;
            }
        );

        const newResources: Resource[] = [
            {
                fieldName: "eventType",
                title: "Type",
                instances: [
                    {
                        id: "Event",
                        text: "Event",
                        color: theme.palette.primary.main,
                    },
                    {
                        id: "Appointment",
                        text: "Appointment",
                        color: theme.palette.secondary.main,
                    },
                ],
            },
            {
                fieldName: "userId",
                title: "User",
                instances: instances,
            },
        ];

        setData({
            events: newData,
            resources: newResources,
        });
    }, [theme.palette.primary.main, theme.palette.secondary.main, loadTimestamp, view]);

    useEffect(() => {
        if (!currentViewName) {
            const firstView = options && options.views && options.views.length > 0 ? options.views[0].name : undefined;
            if (firstView) setViewName(firstView);
        }
    }, [options?.views])

    // TODO: should be conditional on the view options
    const grouping: Grouping[] = [
        {
            resourceName: "userId",
        },
    ];

    const groupViewByUser = (viewName: string) => {
        const view = options?.views?.find(x => x.name === viewName);
        return !!view?.group;
    };

    if (!data) return <Loading />;
    if (!options) return <Alert severity="error">Missing Options</Alert>;

    if (data.events.length < 1) {
        return (
            <Paper
                sx={{
                    overflow: "auto",
                    height: "100%",
                    marginTop: "1px",
                }}
            >
                <Scheduler data={data.events} height={height}>
                    <ViewState currentDate={currentDate} />
                    <MonthView />
                </Scheduler>
            </Paper>
        );
    }

    const onEditingAppointmentChange = (editingAppointment: Partial<AppointmentModel>) => {
        console.log('onEditingAppointmentChange', editingAppointment);
        const { _data } = editingAppointment ?? {};
        const { _id } = _data ?? {};
        appointmentId.current = _id;
    };

    const onCommitChanges = (changes: ChangeSet) => {
        console.log('onCommitChanges', changes, appointmentId.current);

        const changed = (changes as any).changed;

        if (changed.undefined) {
            addAppointment({
                ...changed.undefined,
                appointmentId: appointmentId.current,
            })
        }
    };

    const allowDrag = (appointmentData: AppointmentModel) => {
        const { _data } = appointmentData ?? {};

        return _data.Type === "Appointment" && moment(_data.Start).isAfter(moment());
    }

    const allowResize = (appointmentData: AppointmentModel) => {
        return false;
    }

    const addAppointment = (info: object) => {
        const addAction = view.menu?.items?.find(x => x.visible?.find(a => a === "selectedCount=='0'") && x.name === "Add" && x.type === "ActionMenuItem") as api.ActionMenuItem;
        console.log('onDoubleClick', info, addAction);

        const { startDate } = info as any;
        if (!addAction?.action || !startDate) return;

        const uri = new URI(addAction.action);
        uri.searchParams.append("start", startDate.toISOString());
        uri.searchParams.append("entityId", info["userId"]);
        if (info["appointmentId"]) uri.searchParams.append("appointmentId", info["appointmentId"]);

        view.executeActionAsync({
            action: `${uri.scheme}${uri.getPathAndQuery()}`,
            name: addAction.name,
        })
    };

    const onDoubleClickTimeTableCell = (props: BaseView.TimeTableCellProps) => (e: any) => {
        const { startDate, endDate, groupingInfo } = props;
        const info: { [key: string]: any } = {
            startDate,
            endDate
        };
        groupingInfo?.forEach(g => {
            info[g.fieldName] = g.id;
        });

        addAppointment(info);
    }

    const Appointment = ({
        children, style, ...restProps
    }: Appointments.AppointmentProps & { className?: string; style?: React.CSSProperties;[x: string]: any }) => {
        const { data } = restProps;
        const { _data } = data;
        const { _id } = _data;
        const { Type } = _data;
        const { onObjectMenu, dataView } = useDataViewContext();
        const [isProcessing, setProcessing] = useState(false);

        if (Type !== "Appointment") {
            return (
                <Appointments.Appointment
                    key={_id}
                    {...restProps}
                    style={{
                        ...style,
                        backgroundColor: 'lightGray',
                    }}
                >
                    {children}
                </Appointments.Appointment>
            );
        }

        const onShowPopup = (e: React.MouseEvent) => {
            e.stopPropagation();
            onObjectMenu?.(e.target as HTMLElement, _data, "Appointment");
        }

        const onOpenAppointment = (e: any) => {
            if (isProcessing) return;
            setProcessing(true);

            const id = dataView.id(_data) as string;
            dataView
                .handleUrlAsync(`dataForm:/api/v1/CustomObject/Appointment(${id})/View`, "View")
                .then(x => {
                    setProcessing(false);
                })
                .catch(error => {
                    setProcessing(false);
                });
        };

        return (
            <Appointments.Appointment
                key={_id}
                {...restProps}
                style={{
                    ...style,
                    boxShadow: '4px 4px 4px 2px rgba(0, 0, 0, 0.2)',
                    border: 'none',
                }}
                onDoubleClick={onOpenAppointment}
            >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                    <Typography fontSize={14} style={{ padding: 8, width: '100%' }}>{_data.Name ?? _data.Type}</Typography>
                    {<IconButton onClick={onShowPopup}><MoreVertIcon /></IconButton>}
                </div>
                <Typography fontSize={12} style={{ padding: 8 }}>
                    {moment(_data.Start).format("hh:mm A")} - {moment(_data.End).format("hh:mm A")}
                    <span><br />{_data["UserId|Name"]}</span>
                </Typography>

                {isProcessing && <Loading />}
            </Appointments.Appointment>
        )
    };

    return (
        <Paper
            sx={{
                overflow: "auto",
                height: "100%",
                marginTop: "1px",
            }}
        >
            <Scheduler
                data={data.events}
                height={height}
            >
                <ViewState
                    currentDate={currentDate}
                    currentViewName={currentViewName}
                    onCurrentViewNameChange={setViewName}
                    onCurrentDateChange={setCurrentDate}
                />

                <GroupingState grouping={grouping} groupByDate={groupViewByUser} />

                {options.views?.map(x => (
                    <CalendarView
                        key={x.name}
                        view={x}
                        onDoubleClickTimeTableCell={onDoubleClickTimeTableCell}
                        startHour={startHour}
                        endHour={endHour}
                    />
                ))}

                {/* <AllDayPanel cellComponent={AllDayCell} /> */}

                <Appointments
                    appointmentComponent={Appointment}
                />

                <Resources data={data.resources} mainResourceName="userId" />

                <IntegratedGrouping />

                <GroupingPanel />

                <Toolbar />

                <DateNavigator />

                <ViewSwitcher />

                <TodayButton />

                <EditingState
                    onEditingAppointmentChange={onEditingAppointmentChange}
                    onCommitChanges={onCommitChanges}
                />

                <IntegratedEditing />

                <DragDropProvider
                    allowDrag={allowDrag}
                    allowResize={allowResize}
                />

                <AppointmentTooltip
                    headerComponent={AppointmentTooltipHeaderComponent}
                    contentComponent={AppointmentTooltipComponent}
                />

                <CurrentTimeIndicator shadePreviousAppointments shadePreviousCells />
            </Scheduler>
        </Paper>
    );
}

@observer
export default class CalendarViewComponent extends React.Component<IDataViewComponentProps> {
    render() {
        const { view } = this.props;
        if (view.isLoading) return <Loading />;

        const options = (view.response?.options ?? {}) as api.CalendarViewOptions;
        if (options.views?.[0].type === api.CalendarViewType.Agenda) {
            return (
                <AgendaView {...this.props} loadTimestamp={view.loadTimestamp} />
            );
        }

        const app = App();
        const height = app.innerHeight ?
            app.innerHeight - 48 - 64 - (options.hideToolbar ? 0 : 64) - 8 :
            600;

        return <CalendarViewComponentHook {...this.props} loadTimestamp={view.loadTimestamp} height={height} />;
    }
}

const styles = StyleSheet.create({

})