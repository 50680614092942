import { Alert, Box, Dialog, DialogContent, DialogTitle, LinearProgress } from "@mui/material";
import Paper from "@mui/material/Paper";
import { observer } from "mobx-react";
import * as React from "react";
import * as api from "@crochik/pi-api";

import { observable, toJS } from "mobx";
import App from "../../../application/App";
import { Default } from "../../../context/AppContext";
import { Action } from "../../../context/IForm";
import { IActionMenuItem } from "../../../context/IMenu";
import { DataView } from "../../DataView";
import { FileDropTarget } from "../../FileDropTarget";
import { Form } from "../../Form";
import DataViewToolbarComponent from "../DataViewToolbarComponent";
import { FormBody } from "../FormBody/FormBody";
import { FormTitle } from "../FormTitle";
import { Loading } from "../Loading";
import { DataGridProps } from "./DataGridProps";
import { DataViewFilterFormAutocomplete } from "./DataViewFilterFormAutocomplete";
import { DataViewContext, IDataViewContext, IOnClickArgs } from "./DatavViewContext";
import { IEditEvent } from "./IEditEvent";
import { LocalFilterDialog } from "./LocalFilterDialog";
import { LocalFilterPanel } from "./LocalFilterPanel";
import { ObjectPopupMenu } from "./ObjectPopupMenu";
import { parseResponseError } from "../../../api/Client";
import { URI } from "../../../api/URI";

interface Props extends DataGridProps {
}

interface State {
    error?: string;
    objectPopupAnchor?: HTMLElement;
    selectedObject?: object;
    objectType?: string;
}

@observer
export class DataViewContainer extends React.Component<Props, State> {
    @observable
    private view: DataView | undefined;

    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.load(this.props.view);
    }

    componentWillUnmount() {
        if (!this.view) return;
        this.view.unmount();
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        if (this.props.view !== nextProps.view) {
            this.load(nextProps.view);
        }
    }

    private getIdFromArsgs(props?: Props) {
        if (!props) props = this.props;

        const { args } = props;
        if (args && args.length >= 2 && "id" in args[1]) return args[1].id;
        return undefined;
    }

    private async load(view: string) {
        const { updateAppTitle, criteria, overriddenTitle, breakpoint } = this.props;

        let urlStr = `datagrid:/${view}`;

        if (urlStr.indexOf("{{id}}") > 0) {
            const id = this.getIdFromArsgs(this.props);
            if (id) {
                urlStr = urlStr.replace("{{id}}", id);
            } else {
                console.error(`Can't resolve ${urlStr}`);
                this.setState({ error: "Missing id" });
                return;
            }
        }

        const bp = breakpoint ?? App().breakpoint;
        const newView = await DataView.loadAsync(urlStr, criteria, bp).catch((reason) => {
            console.error("error loading", reason);
            parseResponseError(reason).then((error) => this.setState({ error }));
        });

        if (newView) {
            if (overriddenTitle) newView.data.title = overriddenTitle;
            if (updateAppTitle) App().title = newView.title;
            this.view = newView;
        }
    }

    private async runEditActionAsync(row: Object, field?: string | null): Promise<boolean> {
        if (!this.view || !this.view.actions) return false;
        var editAction: Action | undefined = undefined;
        for (var action of this.view.actions) {
            if (action.name === "edit") {
                editAction = action;
                break;
            }
        }

        if (!editAction || !editAction.action) return false;

        if (await this.view.executeActionAsync(editAction, this.view.id(row))) {
            return true;
        }

        var evt: IEditEvent = {
            id: this.view.id(row),
            row,
            field,
            onClose: App().closeDataFormDialogAsync
        };

        var result = Default.actions.execute(editAction.action, this.view.context, evt);
        if (!result) return false;

        if (result instanceof Form) {
            // this.setState({
            //     ...this.state,
            //     dialogForm: result
            // });

            App().pushFormDialog({
                form: result,
                onActionAsync: this.view.onDialogActionAsync
            });
        }

        return true;
    }

    private onAction = async (action: IActionMenuItem, event: React.MouseEvent) => {
        if (!this.view) return;

        var id: string | undefined = undefined;
        switch (this.view.selectedCount) {
            case 0:
                id = this.getIdFromArsgs();
                break;

            case 1:
                id = this.view.selectedIds[0];
                break;
        }

        if (typeof action.action === "string") {
            var actionPath = action.action;
            if (actionPath.indexOf("{{id}}") > 0 && id) {
                action = {
                    ...action,
                    action: actionPath.replace("{{id}}", id)
                };
            }
        }

        if (await this.view?.executeActionAsync(action, id)) {
            return true;
        }

        if (!action.action) {
            return false;
        }

        return Default.actions.execute(action.action, this.view.context, event);
    };

    onClick = async (e: React.MouseEvent, args: IOnClickArgs) => {
        // console.log("DataViewContainer::onClick", row);

        const { row, field: fieldName, linkUrl } = args;
        e.stopPropagation();

        const { onClick } = this.props;
        if (onClick) {
            const processed = onClick({
                event: e,
                row,
                field: fieldName
            });

            if (processed) return;
        }

        if (!this.view) return;

        const id = this.view.id(row);
        const field = this.view.fields.find((x) => x.name === fieldName);

        if (field && field.name && linkUrl) {
            const value = row[field.name];
            let url = linkUrl;

            const subst = linkUrl.match(/({{[^}]+}})/g) as string[];
            if (subst) {
                for (const s of subst) {
                    if (s === "{{value}}") url = url.replace(s, value);
                    else if (s === "{{id}}") url = url.replace(s, id);
                    else {
                        const fieldName = s.substring(2, s.length - 2);
                        if (fieldName in row) {
                            url = url.replace(s, row[fieldName]);
                        }
                    }
                }
            }

            // if (e.ctrlKey || e.metaKey) {
            await App().selectPageAsync(url, { id });
            // }
            return;
        }

        // hack to handle forms in the detail
        if (this.view.data.detail && this.view.data.detail.page) {
            let url = this.view.data.detail.page;
            const subst = url.match(/({{[^}]+}})/g) as string[];
            if (subst) {
                for (const s of subst) {
                    if (s === "{{id}}") url = url.replace(s, id);
                    else {
                        const fieldName = s.substring(2, s.length - 2);
                        url = url.replace(s, row[fieldName]);
                    }
                }
            }

            // const actionUrl = this.view.data.detail.page.replace("{{id}}", id);
            if (URI.isUri(url) && await this.view?.handleUrlAsync(url, id, "detail")) {
                return;
            }
        }

        // handle master detail
        if (await this.view.showDetailAsync(row, fieldName, e.ctrlKey || e.metaKey)) return;

        // handle edit action if defined
        if (await this.runEditActionAsync(row, fieldName)) return;
    };

    private loadFile = (files: File[]) => {
        this.view?.uploadAsync(files);
    };

    noopClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    private onCloseFilterFormDialogAsync = () => {
        if (!!this.view) {
            this.view.filterVisible = false;
        }
        return Promise.resolve();
    };

    private renderFilterSidePanel() {
        if (!this.view) {
            return null;
        }
        const { filterForm } = this.view;

        if (!!filterForm) {
            const body = filterForm.fields && filterForm.fields.length > 0 ?
                <FormBody key="filterForm" form={filterForm} autoFocus={false} /> :
                undefined;

            return (
                <div style={{ marginLeft: 12 }}>
                    <Paper key="leftPanel" style={{ padding: 12, marginBottom: 12 }}>
                        {body}
                        <DataViewFilterFormAutocomplete view={this.view} />
                        {this.view.isLoading && <Loading key="loading" />}
                    </Paper>
                </div>
            );
        }

        return (
            <Paper
                key="leftPanel"
                style={{
                    height: "100%",
                    overflow: "auto",
                    padding: "6px",
                    backgroundColor: "#e0e0e0"
                }}
            >
                <LocalFilterPanel key="filterPanel" dataView={this.view} fieldNames={this.view.filterableFields} />
            </Paper>
        );
    }

    private renderFilterDialog() {
        if (!this.view || !this.view.filterVisible) return null;

        const { filterForm } = this.view;

        if (!filterForm) {
            return <LocalFilterDialog key="filterDialog" dataView={this.view} />;
        }

        // return <FormDialog form={this.view.filterForm} onCloseAsync={this.onCloseFilterFormDialogAsync} />;
        return (
            <Dialog
                className="FormDialog"
                title={filterForm.title}
                open={true}
                onClose={this.onCloseFilterFormDialogAsync}
                fullWidth={true}
                fullScreen={true}
                scroll="paper"
            >
                <DialogTitle>
                    <FormTitle form={filterForm} onClose={this.onCloseFilterFormDialogAsync} />
                </DialogTitle>

                <DialogContent>
                    <div>
                        <FormBody key="filterForm" form={filterForm} autoFocus={false} />
                        <DataViewFilterFormAutocomplete view={this.view} />
                        {this.view.isLoading && <Loading key="loading" />}
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    private onObjectMenu = (anchor: HTMLElement, row: object, objectType?: string) => {
        console.log("on object menu", toJS(row));
        this.setState({
            objectPopupAnchor: anchor,
            selectedObject: row,
            objectType
        });
    };

    private closeObjectPopup = () => {
        this.setState({
            objectPopupAnchor: undefined,
            selectedObject: undefined
        });
    };

    private onSingleObjectAction = (action: api.ActionMenuItem) => {
        console.log("action", action);

        this.closeObjectPopup();

        const { selectedObject } = this.state;
        if (!selectedObject || !this.view) return;

        this.view.runSingleObjectActionAsync(action, selectedObject);
    };

    render() {
        var { style, children } = this.props;
        const { error, objectPopupAnchor, selectedObject } = this.state;

        if (error) return <Alert severity="error">{error}</Alert>;

        if (!this.view) {
            return (
                <div>
                    <LinearProgress style={{ width: "100%" }} />
                </div>
            );
        }

        const { isLoading, options } = this.view;
        const context: IDataViewContext = {
            dataView: this.view,
            onClick: this.onClick,
            onObjectMenu: this.onObjectMenu
        };

        const objectType = this.state.objectType ?? this.view.objectType;
        const objectId = !!objectType && selectedObject ? this.view.id(selectedObject) : undefined;

        const body = (
            <Box sx={{ height: options?.hideToolbar ? "100%" : "calc(100% - 64px)" }}>
                <DataViewContext.Provider value={context}>
                    {
                        options?.hideToolbar ?
                            null :
                            <DataViewToolbarComponent {...this.props} view={this.view} onAction={this.onAction} />
                    }

                    {children}

                    {isLoading && (
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                zIndex: 100,
                                opacity: 0.2,
                                background: "white"
                                // backdropFilter: 'blur(2px)',
                            }}
                            onClick={this.noopClick}
                        />
                    )}
                    {isLoading && <Loading key="loadingAfter" />}
                    <ObjectPopupMenu
                        key="objectPopupMenu"
                        anchor={objectPopupAnchor}
                        selectedObject={selectedObject}
                        onClose={this.closeObjectPopup}
                        actions={this.view.singleObjectActions}
                        onAction={this.onSingleObjectAction}
                        objectType={objectType}
                        objectId={objectId}
                    />

                </DataViewContext.Provider>
            </Box>
        );

        style = {
            ...style,
            display: "flex",
            height: "100%",
            maxWidth: "100%"
        };

        const bp = this.props.breakpoint ?? App().breakpoint;
        const showFilterSidePanel = bp !== api.ScreenBreakpoint.Xs;

        return (
            <>
                <Box key="body" style={style}>
                    <FileDropTarget
                        key="fileDropTarget"
                        style={{ width: showFilterSidePanel && this.view.filterVisible ? "calc(100% - 300px)" : "100%" }}
                        onFilesDropped={this.view.canUpload ? this.loadFile : undefined}
                    >
                        {body}
                    </FileDropTarget>
                    {showFilterSidePanel && this.view.filterVisible &&
                        <div style={{ width: 300 }}>{this.renderFilterSidePanel()}</div>}
                </Box>
                {!showFilterSidePanel && this.renderFilterDialog()}
            </>
        );
    }
}


