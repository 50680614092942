import React from "react";
import { registerPage } from "src/pi/application/Page";


const AccountDashboard = React.lazy(() => import("./Dashboard/Account"));
registerPage("AccountDashboard", (props) => <AccountDashboard {...props} />);

const Agenda = React.lazy(() => import("./Agenda/UserAgenda"));
registerPage("Agenda", (props) => <Agenda {...props} />);

const BizCalendar = React.lazy(() => import("./Agenda/OrgAgenda"));
registerPage("BizCalendar", (props) => <BizCalendar {...props} />);

const AppointmentType = React.lazy(() => import("./AppointmentType"));
registerPage("AppointmentType", (props) => <AppointmentType {...props} />);

const MyAvailability = React.lazy(() => import("./Availability"));
registerPage("MyAvailability", (props) => <MyAvailability {...props} />);

const MyCalendar = React.lazy(() => import("./Lead/FullCalendar"));
registerPage("MyCalendar", (props) => <MyCalendar {...props} />);

const Events = React.lazy(() => import("./CalendarPage"));
registerPage("Events", (props) => <Events {...props} />);

const OrgAvailability = React.lazy(() => import("./OrgAvailability"));
registerPage("OrgAvailability", (props) => <OrgAvailability {...props} />);

const Report = React.lazy(() => import("./OldReports/Report"));
registerPage("Report", (props) => <Report {...props} />);

const OrgReport = React.lazy(() => import("./OldReports/OrgReport"));
registerPage("OrgReport", (props) => <OrgReport {...props} />);

const Profile = React.lazy(() => import("./Profile"));
registerPage("Profile", (props) => <Profile {...props} />);

const SingerJob = React.lazy(() => import("./SingerJob"));
registerPage("SingerJob", (props) => <SingerJob {...props} />);

const Transaction = React.lazy(() => import("./Transaction"));
registerPage("Transaction", (props) => <Transaction {...props} />);

// ...

const Appointment = React.lazy(() => import("./Appointment"));
registerPage("Appointment", (props) => <Appointment {...props} />);

const CatalogComponent = React.lazy(() => import("./Catalog/CatalogComponent"));
registerPage("ProductCatalog", (props) => <CatalogComponent {...props} />);

const Dipper = React.lazy(() => import("src/pi/ui/Dipper"));
registerPage("Dipper", (props) => <Dipper {...props} />);

const LeadType = React.lazy(() => import("./LeadType"));
registerPage("LeadType", (props) => <LeadType {...props} />);

const Lead = React.lazy(() => import("./Lead"));
registerPage("Lead", (props) => <Lead {...props} />);

// const Form = React.lazy(() => import("./Form"));
// registerPage("Form", (props) => <Form {...props} />);

const Organization = React.lazy(() => import("./Organization"));
registerPage("Organization", (props) => <Organization {...props} />);

const Dashboard = React.lazy(() => import("./Dashboard"));
registerPage("Dashboard", (props) => <Dashboard {...props} />);

const CustomObject = React.lazy(() => import("./CustomObject"));
registerPage("CustomObject", (props) => <CustomObject {...props} />);

const ReportDesigner = React.lazy(() => import("./ReportDesigner"));
registerPage("ReportDesigner", (props) => <ReportDesigner {...props} />);

const ReportViewer = React.lazy(() => import("./ReportViewer"));
registerPage("ReportViewer", (props) => <ReportViewer {...props} />);

const DragAndDrop = React.lazy(() => import("./DragAndDrop/FormBuilder"));
registerPage("DragAndDrop", (props) => <DragAndDrop {...props} />);

// const FormBuilder = React.lazy(() => import("./FormBuilder"));
// registerPage("FormBuilder", (props) => <FormBuilder {...props} />);

const EmailEditor = React.lazy(() => import("src/pi/ui/Unlayer"));
registerPage("EmailEditor", (props) => <EmailEditor {...props} />);

const CallcenterScheduler = React.lazy(() => import("./Scheduler/callcenter"));
registerPage("CallcenterScheduler", (props) => <CallcenterScheduler {...props} />);

const ZeeScheduler = React.lazy(() => import("./Scheduler/zee"));
registerPage("Scheduler", (props) => <ZeeScheduler {...props} />);

const OrganizationScheduler = React.lazy(() => import("./Scheduler/OrganizationScheduler"));
registerPage("OrganizationScheduler", (props) => <OrganizationScheduler {...props} />);

const Locations = React.lazy(() => import("./Locations"));
registerPage("Locations", (props) => <Locations {...props} />);

const Swagger = React.lazy(() => import("src/pi/ui/Swagger"));
registerPage("Swagger", (props) => <Swagger {...props} />);

// const Flow2 = React.lazy(() => import("./Flow2"));
// const Flow = React.lazy(() => import("./Flow"));
const Flow2 = React.lazy(() => import('src/pi/ui/Flow2/Body'));
registerPage("Flow2", (props) => <Flow2 {...props} />);
registerPage("Flow", (props) => <Flow2 {...props} />);


const BI = React.lazy(() => import("src/pi/ui/BI"));
registerPage("BI", (props) => <BI {...props} />);

const ObjectTypeDesigner = React.lazy(() => import("src/pi/ui/ObjectTypeDesigner"));
registerPage("ObjectTypeDesigner", (props) => <ObjectTypeDesigner {...props} />);

// const Welcome = React.lazy(() => import("./Welcome"));
// registerPage("Welcome", (props) => <Welcome {...props} />);


export default function init() {
    console.debug('register components')
}