import { Alert, Card, IconButton, Typography } from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import { useCallback } from "react";
import * as api from "@crochik/pi-api";
import { CardProps } from ".";
import { NamedIcon } from "../../ActionMenuBar/NamedIcon";
import { ReadOnlyFieldValue } from "../../FormBody/FormCell/ReadOnlyFormCell";
import { IOnClickArgs } from "../DatavViewContext";

interface Props extends CardProps {
    fields: { [key: string]: api.FormField };
    options?: api.CardDataViewOptions;
    layout: api.GridFormLayout;
}

export function LayoutCard(props: Props) {
    const { fields, options, onClick, row, view, onObjectMenu, layout } = props;
    const { singleObjectActions } = view;

    const args: IOnClickArgs = {
        row,
    };

    const onClickHandler = (e: React.MouseEvent) => onClick(e, args);
    const onPopupHandler = (e: React.MouseEvent) => {
        console.log('on object menu')
        e.stopPropagation();
        e.bubbles = false;

        onObjectMenu?.(e.target as HTMLElement, row);
    };

    const renderCell = useCallback((field, rowIndex, fieldIndex, flex) => {
        if (!options?.showLabels) {
            return (
                <div key={`row-${rowIndex}-field-${fieldIndex}`} style={{ flex, padding: 4, width: '100%', position: 'relative' }}>
                    <ReadOnlyFieldValue row={row} field={field} value={row[field.name!]} />
                </div>
            );
        }

        return (
            <div key={field.name} style={{ marginTop: 4, marginBottom: 4, backgroundColor: "#fcfcfc", padding: 4, borderRadius: 6 }} >
                <Typography variant="subtitle2" color="default">
                    {field.label || field.name}
                </Typography>
                <ReadOnlyFieldValue row={row} field={field} value={row[field.name!]} />
            </div>
        );
    }, [options?.showLabels, row]);

    if (!layout.rows) {
        return <Alert severity="error">Missing Layout</Alert>;
    }

    return (
        <Card style={{ width: '100%', padding: '8px', display: 'flex', flexDirection: 'row', position: 'relative' }}>
            <div onClick={onClickHandler} style={{ flex: 1, cursor: 'pointer', width: '100%', position: 'relative' }}>
                {
                    layout.rows.map((rowLayout, rowIndex) => {
                        const nFields = rowLayout.fields?.length;
                        return (
                            <div key={`row-${rowIndex}`} className={css(styles.row)}>
                                {
                                    nFields && rowLayout.fields?.map((field, fieldIndex) => {
                                        const totalWidth = rowLayout.fields!.reduce((p, f) => p + (f.width ?? 1), 0)
                                        const flex = 12 * (field.width ?? 1) / totalWidth;
                                        const fullField = fields[field.name!];
                                        if (!fullField) {
                                            return <div key={`row-${rowIndex}-field-${fieldIndex}`} style={{ flex, padding: 4, }}>{field.name}</div>;
                                        }

                                        return renderCell(fullField, rowIndex, fieldIndex, flex);
                                    })
                                }
                            </div>
                        );
                    })
                }
            </div>
            {
                singleObjectActions && onPopupHandler && (
                    <div>
                        <IconButton color="inherit" onClick={onPopupHandler}>
                            <NamedIcon icon="More" />
                        </IconButton>
                    </div>
                )
            }
        </Card>

    );
}

const styles = StyleSheet.create({
    row: {
        display: "flex",
        flex: 1,
        position: 'relative',
        width: '100%',
    },
});
