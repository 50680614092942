import { BaseView } from "@devexpress/dx-react-scheduler";
import { DayView, MonthView, WeekView } from "@devexpress/dx-react-scheduler-material-ui";
import React from "react";
import * as api from "@crochik/pi-api";

export interface ICalendarViewProps {
    view: api.CalendarView;
    onDoubleClickTimeTableCell: (props: BaseView.TimeTableCellProps) => (e: any) => void;
    startHour?: number | null;
    endHour?: number | null;
}

export function CalendarView({ startHour, endHour, view, onDoubleClickTimeTableCell }: ICalendarViewProps) {
    const MonthViewTimeTableCell = React.memo(({ onDoubleClick, ...restProps }: MonthView.TimeTableCellProps) => (
        <MonthView.TimeTableCell
            {...restProps}
            onDoubleClick={onDoubleClickTimeTableCell(restProps)} />
    ));

    const WeekViewTimeTableCell = React.memo(({ onDoubleClick, ...restProps }: WeekView.TimeTableCellProps) => (
        <WeekView.TimeTableCell
            {...restProps}
            onDoubleClick={onDoubleClickTimeTableCell(restProps)} />
    ));

    const DayViewTimeTableCell = React.memo(({ onDoubleClick, ...restProps }: DayView.TimeTableCellProps) => (
        <DayView.TimeTableCell
            {...restProps}
            onDoubleClick={onDoubleClickTimeTableCell(restProps)} />
    ));

    const { type, name } = view;
    if (type === api.CalendarViewType.Day) return (
        <DayView
            key="Day"
            startDayHour={startHour ?? 9}
            endDayHour={endHour ?? 19}
            timeTableCellComponent={DayViewTimeTableCell}
            intervalCount={1}
            name={name!} />
    );

    if (type === api.CalendarViewType.SevenDays) return (
        <DayView
            key="SevenDays"
            startDayHour={startHour ?? 9}
            endDayHour={endHour ?? 19}
            timeTableCellComponent={DayViewTimeTableCell}
            intervalCount={7}
            name={name!} />
    );

    if (type === api.CalendarViewType.MondayToFriday) return (
        <WeekView
            key="MondayToFriday"
            startDayHour={startHour ?? 9}
            endDayHour={endHour ?? 19}
            excludedDays={[0, 6]}
            name={name!}
            timeTableCellComponent={WeekViewTimeTableCell} />
    );

    if (type === api.CalendarViewType.Week) return (
        <WeekView
            key="Week"
            startDayHour={startHour ?? 9}
            endDayHour={endHour ?? 19}
            name={name!}
            timeTableCellComponent={WeekViewTimeTableCell} />
    );

    if (type === api.CalendarViewType.Month) return (
        <MonthView
            key="Month"
            timeTableCellComponent={MonthViewTimeTableCell}
            name={name!}
        />
    );

    return null;
}
