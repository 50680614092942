import { AppointmentTooltip } from "@devexpress/dx-react-scheduler-material-ui";
import { Typography } from "@mui/material";
import moment from "moment";
import { useDataViewContext } from "../DatavViewContext";
import { ICalendarRow } from "./ICalendarRow";

// interface IActionsMenuProps {
//     menu?: api.Menu;
//     onAction: (item: api.MenuItem) => void;
//     context: object;
// }
// function ActionsMenu({ menu, onAction, context }: IActionsMenuProps) {
//     if (!menu) return <Loading />
//     if (!menu.items || menu.items.length < 1) return null;
//     const onClickAction = (action: api.MenuItem) => () => {
//         onAction(action);
//     }
//     return (<div style={{ paddingTop: 12 }}>
//         {menu.items.map(x =>
//             <Button key={action.name} variant="outlined" onClick={onClickAction(x)} disabled={!evaluateWithContext(context, x.enable)}>
//                 {x.label ?? x.name}
//             </Button>)}
//     </div>
//     );
// }
export function AppointmentTooltipComponent(props: AppointmentTooltip.ContentProps) {
    const dataViewContext = useDataViewContext();
    // const { dataView } = dataViewContext;
    const { appointmentData } = props;
    const row = appointmentData?.["_data"] as any as ICalendarRow;
    // const status: Status = moment(row.Start).isAfter(moment()) ? "Future" : "InThePast";


    // const onAction = (action: api.MenuItem) => async () => {
    //     await dataView.runSingleObjectActionAsync(action, row);
    // };

    // const context = {
    //     ...row,
    //     selectedCount: 1
    // };

    // const actions = dataView.menu?.items?.filter(x => x.type === "ActionMenuItem").filter(x => evaluateWithContext(context, x.visible));

    return (
        <div style={{
            padding: 12,
            paddingTop: 3,
        }}>
            <Typography>
                on {moment(row.Start).format("MM/D/YY")} at {moment(row.Start).format("hh:mm A")}
                <span><br />with  {row["UserId|Name"]}</span>
            </Typography>
            {/* {actions && (
                <div style={{ paddingTop: 12 }}>
                    {actions.map(x => <Button key={action.name} variant="outlined" onClick={onAction(x)} disabled={!evaluateWithContext(context, x.enable)}>
                        {x.label ?? x.name}
                    </Button>)}
                </div>
            )} */}
        </div>
    );
}
