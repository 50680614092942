import { Box, Stack } from "@mui/material";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";
import * as api from "@crochik/pi-api";
import { Loading } from "../../Loading";
import { useDataViewContext } from "../DatavViewContext";
import { IDataViewComponentProps } from "../IDataViewComponentProps";
import { DefaultCard } from "./DefaultCard";
import { LayoutCard } from "./LayoutCard";
import { NotificationCard } from "./NotificationCard";

interface State {
    filterElement?: Element;
    filterField?: string;
}

export interface CardProps extends IDataViewComponentProps {
    id: string;
    row: object;
}

interface ICardViewHookProps extends IDataViewComponentProps {
    records: string[];
}

function CardViewHook({ onClick, onObjectMenu }: ICardViewHookProps) {
    const viewContext = useDataViewContext();
    const { dataView: view } = viewContext;
    const { response, records } = view;

    const { objectType, options } = view;
    const cardOptions = options?.type === "Card" ? options as api.CardDataViewOptions : undefined;

    const [fields, setFields] = useState<{ [name: string]: api.FormField }>();

    const [gridLayout, setGridLayout] = useState<api.GridFormLayout>();

    useEffect(() => {
        console.log('new response');
    }, [response]);

    useEffect(() => {
        setFields(undefined);
        if (!view?.fields) return;

        const newFields: { [name: string]: api.FormField } = {};
        cardOptions?.fields?.forEach(x => newFields[x.name!] = x);
        view.fields.forEach(x => {
            if (!x.name || newFields[x.name]) return;
            if (!view.isFieldVisible(x.name)) return;
            newFields[x.name] = x;
        });
        setFields(newFields);

        if (cardOptions?.formLayout) {
            setGridLayout(cardOptions?.formLayout);
            return;
        }

        const layout: api.GridFormLayout = {
            t: "GridFormLayout",
            type: "Grid",
            rows: view.fields
                .filter(x => view.isFieldVisible(x.name!))
                .map(x => {
                    return {
                        fields: [
                            {
                                name: x.name,
                                width: 1
                            }
                        ]
                    }
                })
        };

        console.log(layout);

        setGridLayout(layout);

    }, [view?.fields, cardOptions?.fields, cardOptions?.formLayout]);

    const loadMore = useCallback(() => {
        view.loadMoreAsync();
    }, [view]);

    const renderCard = useCallback((row: object) => {
        const id = view.id(row);

        switch (objectType) {
            case "Notification":
                return <NotificationCard view={view} onClick={onClick} key={id} row={row} id={id} />;
        }

        if (!fields) return null;
        if (gridLayout) {
            return <LayoutCard view={view} onClick={onClick} key={id} row={row} id={id} fields={fields} options={cardOptions} onObjectMenu={onObjectMenu} layout={gridLayout} />;
        }

        return <DefaultCard view={view} onClick={onClick} key={id} row={row} id={id} />;
    }, [view, fields]);

    return (
        <Box
            sx={{
                overflow: "auto",
                height: "100%",
                padding: '12px',
                backgroundColor: "#eeeeee",
            }}
        >
            <Stack spacing={1}>
                {records.map((row) => renderCard(view.get(row)))}

                {!view.isLoading && view.canLoadMore && <Waypoint bottomOffset="-300px" onEnter={loadMore} />}
            </Stack>
        </Box>
    );
}

@observer
export class CardViewComponent extends React.Component<IDataViewComponentProps, State> {

    render() {
        const { view, onClick, onObjectMenu } = this.props;
        const { records } = view;
        if (!records) return <Loading />

        return <CardViewHook records={records} onClick={onClick} view={view} onObjectMenu={onObjectMenu} />
    }
}
