import { action, computed, IObservableArray, observable, runInAction, toJS } from "mobx";

import moment from "moment";
import * as api from "@crochik/pi-api";
import * as uuid from "uuid";
import { URI } from "../api/URI";
import App, { IFormDialog } from "../application/App";
import { Default } from "../context/AppContext";
import { IDataView } from "../context/IDataView";
import { Action, IField, IForm, TYPE } from "../context/IForm";
import { IMenu } from "../context/IMenu";
import { CSVWriter } from "../csv";
import DataService, { Condition, IDataFormActionRequest, IDataFormActionResponse, IDataViewRequest, IDataViewResponse } from "../services/DataService";
import DialogService from "../services/Dialog";
import { Form } from "./Form";
import { parseResponseError } from "../api/Client";

export interface ILookupValue {
    value: any;
    name: string;
    count: number;
}

export interface LatLngBounds {
    /**
     * East longitude in degrees. Values outside the range [-180, 180] will be
     * wrapped to the range [-180, 180). For example, a value of -190 will be
     * converted to 170. A value of 190 will be converted to -170. This reflects
     * the fact that longitudes wrap around the globe.
     */
    east: number;
    /**
     * North latitude in degrees. Values will be clamped to the range [-90, 90].
     * This means that if the value specified is less than -90, it will be set
     * to -90. And if the value is greater than 90, it will be set to 90.
     */
    north: number;
    /**
     * South latitude in degrees. Values will be clamped to the range [-90, 90].
     * This means that if the value specified is less than -90, it will be set
     * to -90. And if the value is greater than 90, it will be set to 90.
     */
    south: number;
    /**
     * West longitude in degrees. Values outside the range [-180, 180] will be
     * wrapped to the range [-180, 180). For example, a value of -190 will be
     * converted to 170. A value of 190 will be converted to -170. This reflects
     * the fact that longitudes wrap around the globe.
     */
    west: number;
}

export interface State {
    isLoading: boolean;
    isReloading: boolean;
    query: object;
    selected: object;
    records: string[];
    sortBy?: string;
    reverseSort?: boolean;
    selectedCount: number;
    fields?: IField[];
    title?: string;
    search?: string;
    isLastPage: boolean;
    loadTimestamp?: Date;

    fieldsVisibility?: { [name: string]: boolean };
    fieldsOrder?: string[];
    fieldsLookup: { [fieldName: string]: { [value: string]: ILookupValue } };

    // new filter "exact match"
    filter?: { [fieldName: string]: ILookupValue[] };
    filterVisible: boolean;

    response?: IDataViewResponse;
    data: IDataView;

    viewSelected: boolean;
    singleObjectActions?: api.MenuItem[];

    mapBounds?: LatLngBounds;
}

export interface DetailViewArgs {
    id: string;
    field?: string | null;
    value?: any;
    criteria?: Condition[];
}

export class DataView {
    private readonly _name: string;
    private readonly _state: State;
    private raw: object[];
    private index: { [name: string]: object };
    private _url?: string;
    private _objectType?: string | null;
    private _fixedFields?: { [name: string]: any };

    private _batchChanges = false;
    private _searchTimeout?: number;

    public filterForm?: Form;

    static create(data: IDataView): DataView {
        return new DataView(data.name ?? "error", data);
    }

    // static get(name: string): DataView {
    //     return Default.ui.get(name, "dataView");
    // }

    static getState(name: string): State {
        return Default.state.get(name, "dataView");
    }

    static bindAction(name: string, action: string, funct: Function) {
        Default.actions.set(action, funct, `dataView.${name}`);
    }

    static async loadAsync(urlStr: string, criteria?: Condition[], breakpoint?: api.ScreenBreakpoint): Promise<DataView | undefined> {
        var request: IDataViewRequest = {
            criteria: criteria || [],
            breakpoint,
        };

        const url = new URI(urlStr);

        if (url.query) {
            // reset criteria using just the query parameters
            request.criteria = [];

            url.searchParams.forEach((v, k) =>
                request.criteria?.push({
                    fieldName: k,
                    value: v,
                })
            );
        }

        const path = url.path;

        const view = await this.fetchRequestAsync(request, undefined, path);

        if (url.fragment && view) {
            const hash = url.fragment.substring(1); // remove #
            const pairs = hash.split("&");
            const args: { [name: string]: string } = {};
            pairs.forEach((x) => {
                const parts = x.split("=");
                if (parts.length !== 2) return;
                args[parts[0]] = parts[1];
            });
            if ("title" in args) {
                view._state.data.title = decodeURIComponent(args["title"]);
            }
        }

        return view;
    }

    static getFixedFields(request: IDataViewRequest): { [name: string]: object } | undefined {
        if (!request.criteria || request.criteria.length < 1) return undefined;

        const fixedFilters: { [name: string]: object } = {};
        request.criteria.forEach((x) => (x.fieldName ? (fixedFilters[x.fieldName] = x.value) : null));
        return fixedFilters;
    }

    static async fetchRequestAsync(request: IDataViewRequest, view?: DataView, url?: string): Promise<DataView | undefined> {
        // console.log("fetchRequest", request);

        request = toJS(request);
        if (!url) url = view?._url;
        if (!url) throw new Error("Missing url");

        const fixedFields = view ? (request.view ? [] : view._fixedFields) : this.getFixedFields(request);
        request.hash = this.calculateHash(fixedFields);
        request.fixedFields = fixedFields ? Object.keys(fixedFields) : undefined;

        if (!view && !request.view) {
            // first load, get last filter from cache
            request = DataView.updateRequest(url, request);
        }

        var response = await DataService().dataViewAsync(url, request);
        if (!response) {
            console.debug("failed request", response);
            return;
        }

        if (!response.view || !response.view.name || !response.request) return;

        if (!response.request.fields) {
            response.request.fields = response.view.fields?.map((x) => x.name!);
        }

        if (!view) {
            // first load
            view = new DataView(response.view.name, response.view);
            view._url = url;
            view._fixedFields = fixedFields;
            view._objectType = response.objectType;

            var searchCriteria = response.request.criteria?.find((x) => x.fieldName === "#text");
            if (searchCriteria) {
                view._state.search = searchCriteria.value;
            }
        } else if (!request.view) {
            // if not a view, update session cache
            DataView.updateSession(url, request);
        }

        view._batchChanges = false;

        view._state.viewSelected = false;
        view._state.data = response.view;
        view._state.response = response;
        view._state.fieldsVisibility = undefined; // force updating list of fields
        view.updateFields();

        const orderedBy = response.request.orderBy || response.view.defaultSort;
        // console.log("orderby", response.request.orderBy, response.view.defaultSort, orderedBy);
        if (!!orderedBy) {
            if (orderedBy.startsWith("-")) {
                view._state.reverseSort = true;
                view._state.sortBy = orderedBy.substring(1);
            } else {
                view._state.sortBy = orderedBy;
            }
        }

        const isSelectable = !!response.view.isSelectable; // response.options?.type !== "Card" &&

        const actions: api.MenuItem[] = [];
        this.addSingleObjectActions(actions, isSelectable, response.view.menu ?? undefined);
        view._state.singleObjectActions = actions.length > 0 ? actions : undefined;

        if (response.result) {
            view.onDataLoaded(response.result);
        }

        if (response.message) {
            DialogService.inform({
                title: "Success",
                message: response.message,
            });
        }

        if (response.nextUrl && !response.nextUrl.startsWith("#")) {
            const result = await App().executeAsync(response.nextUrl);
            // TODO: ...
        }

        return view;
    }

    private static addSingleObjectActions(result: api.MenuItem[], isSelectable: boolean, menu?: api.Menu) {
        if (!menu || !menu.items) return;

        for (var child of menu.items) {
            if ('items' in child) {
                this.addSingleObjectActions(result, isSelectable, child as api.Menu);
                continue;
            }

            if (!child.visible) continue;
            if (child.visible.find(x => x === "selectedCount=='1'") || (!isSelectable && child.visible.find(x => x === "selectedCount!='0'"))) {
                result.push(child);
            }
        }
    }

    static calculateHash(fixedFields?: { [name: string]: any }): string {
        if (!fixedFields) return "";
        const fields = Object.keys(fixedFields);
        if (fields.length === 0) return "";

        fields.sort();
        return fields.join(":");
    }

    static updateRequest(url: string, request: IDataViewRequest): IDataViewRequest {
        const key = `dataView:/${url}#${request.hash}`;

        const value = sessionStorage.getItem(key);
        if (!value) {
            return request;
        }

        const cache: IDataViewRequest = JSON.parse(value);
        if (!!cache.criteria) {
            const criteria: { [key: string]: Condition } = {};
            (request?.criteria || []).forEach((x) => (criteria[x.fieldName || ""] = x));
            cache.criteria.forEach((x) => {
                if (!x.fieldName) return;
                if (x.fieldName in criteria) return;
                criteria[x.fieldName] = x;
            });
            request.criteria = Object.keys(criteria).map((fieldName) => criteria[fieldName]);
        }

        if (!!cache.orderBy) {
            request.orderBy = cache.orderBy;
        }

        return request;
    }

    static updateSession(url: string, request: IDataViewRequest) {
        const key = `dataView:/${url}#${request.hash}`;
        const criteria = request.criteria
            ? request.criteria.filter((x) => x.fieldName && (!request.fixedFields || !request.fixedFields.includes(x.fieldName)))
            : [];
        const value = {
            url,
            criteria: criteria,
            orderBy: request.orderBy,
            fixedFields: request.fixedFields,
        };

        sessionStorage.setItem(key, JSON.stringify(value));
    }

    get objectType() {
        return this._objectType;
    }

    get fixedFields(): { [name: string]: any } | undefined {
        return this._fixedFields;
    }

    get fields(): IField[] {
        return this.data?.fields ?? [];
    }
    get filterableFields() {
        return this._state.data.filter || [];
    }

    @computed
    get viewName() {
        return this._state.response?.request?.view;
    }

    @computed
    get breakpoint() {
        return this._state.response?.request?.breakpoint;
    }

    @computed
    get options() {
        return this._state.response?.options;
    }

    @computed
    get component() {
        return this.options?.type ?? "Auto";
    }

    @computed
    get filterVisible() {
        return this._state.filterVisible;
    }
    set filterVisible(visible: boolean) {
        this._state.filterVisible = visible;
    }

    @computed
    get orderedFields(): IField[] {
        return this._state.fields || this.fields;
    }

    @computed
    get filteredFields() {
        return this._state.filter ? Object.keys(this._state.filter) : [];
    }

    @computed
    get visibleFields(): IField[] {
        return this.orderedFields.filter((x) => x.name && this.isFieldVisible(x.name));
    }

    @computed
    get isLoading(): boolean {
        return this._state.isLoading;
    }

    @computed
    get records(): string[] {
        return this._state.records;
    }

    @computed
    get filteredCount(): number {
        return this._state.records.length;
    }

    @computed
    get selectedCount(): number {
        return this._state.selectedCount;
    }

    @computed
    get loadedFields() {
        return this._state.response?.request?.fields;
    }

    @computed
    get loadTimestamp() {
        return this._state.loadTimestamp;
    }

    get context(): string {
        return `dataView.${this.name}`;
    }
    get name(): string {
        return this._name;
    }
    get data() {
        return this._state.data;
    }
    get isSelectable(): boolean {
        return this.data.isSelectable ? true : false;
    }

    get singleObjectActions(): api.MenuItem[] | undefined {
        return this._state.singleObjectActions;
    }

    get isFilterableLocally(): boolean {
        return !!this._state.data.isFilterableLocally && !!this._state.data.filter && !this.canLoadMore && !this._state.data.pageSize;
    }
    get isViewFilterable(): boolean {
        if (this.isFilterableLocally) return true;
        if (!this._state.data.filterForm?.fields || this._state.data.filterForm.fields.length === 0) return false;

        if (!this._fixedFields) return true;

        // check that there is at least one field that is not "fixed"
        for (const field of this._state.data.filterForm.fields) {
            if (field.name && !(field.name in this._fixedFields)) return true;
        }

        return false;
    }
    get title(): string {
        return this._state.title || this.data.title || this.name;
    }
    get isFilterVisible() {
        return this.isSearchable || (this.data.filter && !this.canLoadMore);
    }
    get isSearchable() {
        return this.data.searchable;
    }
    get actions(): Action[] | undefined | null {
        return this.data.actions;
    }
    get query(): object {
        return this._state.query;
    }
    get sortedBy(): string | undefined {
        return this._state.sortBy;
    }
    get isSortReversed(): boolean {
        return this._state.reverseSort ? true : false;
    }
    get search() {
        return this._state.search;
    }

    get selectedIds(): string[] {
        var ids: string[] = [];
        this._state.records.forEach((id) => {
            if (this._state.selected[id]) ids.push(id);
        });

        return ids;
    }

    @computed
    get menu(): IMenu | undefined {
        return this._state.data.menu ?? undefined;
    }

    @computed
    get response(): IDataViewResponse | undefined {
        return this._state.response;
    }

    @computed
    get canLoadMore(): boolean {
        return !!this._url && !this._state.isLastPage;
    }

    @computed
    get canUpload(): boolean {
        return !!this._url && !this.isLoading;
    }

    get baseUrl() {
        return this._url;
    }

    get mapBounds() {
        return this._state.mapBounds;
    }

    set mapBounds(value: LatLngBounds|undefined) {
        this._state.mapBounds = value;
    }

    constructor(name: string, data: IDataView) {
        this._name = name;

        this.index = {};
        this.raw = [];

        let { defaultSort } = data;
        let reverseSort = false;
        if (defaultSort && defaultSort[0] === "-") {
            defaultSort = defaultSort.substring(1);
            reverseSort = true;
        }

        var state: State = {
            fields: undefined,
            isLoading: true,
            isReloading: false,
            query: {},
            records: [],
            reverseSort,
            search: undefined,
            selected: {},
            selectedCount: 0,
            sortBy: defaultSort ?? undefined,
            fieldsLookup: {},
            filterVisible: false,
            isLastPage: false,
            data,
            viewSelected: false,
            singleObjectActions: undefined,
        };

        this._state = observable(state);

        // Default.ui.set(name, this, "dataView");
        Default.state.set(name, this._state, "dataView");

        this.initFilterForm();
    }

    isSortable(field: IField): boolean {
        return !!this._state.data.filter && !!field.name && this._state.data.filter.indexOf(field.name) >= 0;
    }

    isSorted(field: IField): boolean {
        return this.sortedBy === field.name;
    }

    isFiltered(field: IField): boolean {
        if (!this.isViewFilterable || !field.name) return false;

        if (this._state.response?.request?.criteria?.find((x) => x.fieldName === field.name)) {
            // console.log(`${field.name} was filtered in the request`);
            return true;
        }

        if (this.filteredFields.indexOf(field.name) >= 0) {
            // console.log(`${field.name} is filtered locally`);
            return true;
        }

        return false;
    }

    isFilterable(field: IField): boolean {
        if (!this.isViewFilterable || !field.name) return false;

        if (!!this._state.data?.filterForm?.fields) {
            return !!this._state.data.filterForm.fields.find((x) => x.name === field.name);
        }

        return this.filterableFields.indexOf(field.name) >= 0;
    }

    hasLocalLookup(field: IField): boolean {
        if (this.canLoadMore || !field.name) return false;
        const lookup = this.getFieldLookup(field.name);
        return lookup && Object.values(toJS(lookup)).length > 1;
    }

    private runSearch = (value?: string, args?: any[]) => () => {
        if (!this._url) {
            throw new Error("Missing url");
        }

        this.onFilterChanged();
    };

    calcCriteria() {
        let criteria: Condition[] = [];

        const request = toJS(this._state.response?.request ?? {});
        if (request.criteria) {
            request.criteria.forEach((x) => {
                if (!x.fieldName) return;
                if (x.fieldName === "#text") return;
                criteria.push(x);
            });
        }

        if (this.filterForm?.fields) {
            for (const field of this.filterForm.fields) {
                if (!field.name) continue;
                if (this._fixedFields && this._fixedFields[field.name]) continue;

                const fieldValue = this.filterForm.getValue(field);

                // remove current filters
                criteria = criteria.filter((x) => x.fieldName !== field.name);

                if (fieldValue === undefined || (typeof fieldValue === "string" && fieldValue.trim().length === 0)) {
                    // null, or empty string => not filtered
                    continue;
                }

                if (Array.isArray(fieldValue) && fieldValue.length === 0) {
                    // empty array => not filtered
                    continue;
                }

                switch (field.type) {
                    case TYPE.MULTIREFERENCE:
                    case TYPE.MULTISELECT:
                        if (Array.isArray(fieldValue)) {
                            if (fieldValue.length > 0) {
                                // TODO: ideally the field would not send an array with just one element
                                // ...
                                criteria.push({
                                    fieldName: field.name,
                                    operator: api.Operator.In,
                                    value: fieldValue,
                                });
                            }
                            continue;
                        } else {
                            // TODO: handle special client side place holders
                            // #none, #any,
                            // ...
                        }
                        continue;

                    case TYPE.DATERANGE: {
                        // daterange is always array[2]
                        if (Array.isArray(fieldValue) && fieldValue.length === 2) {
                            if (fieldValue[0]) {
                                criteria.push({
                                    fieldName: field.name,
                                    operator: api.Operator.Gte,
                                    value: fieldValue[0],
                                });
                            }
                            if (fieldValue[1]) {
                                // TODO: check precision (assume day for now)
                                if (typeof fieldValue[1] !== "string") {
                                    // assume it is date
                                    const date = fieldValue[1] as Date;
                                    const endOfDay = moment(date).add(1, "day").subtract(1, "second");
                                    console.log(date, endOfDay);
                                    criteria.push({
                                        fieldName: field.name,
                                        operator: api.Operator.Lt,
                                        value: endOfDay,
                                    });
                                } else {
                                    criteria.push({
                                        fieldName: field.name,
                                        operator: api.Operator.Lt,
                                        value: fieldValue[1],
                                    });
                                }
                            }
                        }
                        continue;
                    }

                    case TYPE.LOCATIONDISTANCE: {
                        console.log('distance filter', fieldValue);
                        criteria.push({
                            fieldName: field.name,
                            operator: api.Operator.Lt,
                            value: fieldValue,
                        });
                        continue;
                    }
                }

                // TODO: handle other operators?
                // ...

                criteria.push({
                    fieldName: field.name,
                    operator: api.Operator.Eq,
                    value: fieldValue,
                });
            }
        }

        // criteria = criteria.filter((x) => x.fieldName !== "#text");

        if (this.search && this.search.length > 0) {
            // full text search
            criteria.push({
                fieldName: "#text",
                operator: api.Operator.Eq,
                value: this.search,
            });
        }

        return criteria;
    }

    buildRequest(): IDataViewRequest {
        const last = this._state.response ? toJS(this._state.response.request) : {};
        const request = { ...last };
        delete request.skip;

        request.criteria = this.calcCriteria();

        if (this.sortedBy && this.sortedBy.length > 0) {
            request.orderBy = (this.isSortReversed ? "-" : "") + this.sortedBy;
        }

        const visibleFields = this.visibleFields;
        if (!!visibleFields && visibleFields.length > 0) {
            request.fields = visibleFields.filter((x) => !!x.name).map((x) => x.name as string);
        }

        return request;
    }

    private onFilterChanged = () => {
        if (!this._url) {
            throw new Error("Missing url");
        }

        if (this._batchChanges) return;

        this._state.records = [];
        this._state.isReloading = true;
        this._state.isLoading = true;
        const request = this.buildRequest();
        delete request.view;

        this.fetchAsync(request);
    };

    async saveViewAsync(input: object): Promise<IDataFormActionResponse> {
        const client = App().apiClient;
        if (!client || !this._url) {
            return {
                success: false,
                message: "Invalid state",
                action: "#cancel",
            };
        }

        const request: api.SaveDataViewRequest = {
            ...input,
            request: this.buildRequest(),
        };

        const url = `${this._url}/Dataview/Save`;

        return await client.postJson<IDataFormActionResponse>(url, request);
    }

    unmount() {
        if (this.filterForm) {
            this.filterForm.unmount();
        }
    }

    initFilterForm() {
        const data = this._state.data;

        if (!data.filterForm?.fields || data.filterForm.fields.length < 1) return;

        if (data.filterForm) {
            data.filterForm.name = uuid.v4().toString();

            const filterForm = Form.create(data.filterForm, null);
            // filterForm.bindAction("Default", () => {
            //     this.onFilterChanged();
            // });

            filterForm.fields?.forEach((f) => {
                if (!f.name) return;

                if (f.type === "text") {
                    // do not intercept changes to text
                    return;
                }
                filterForm.bindOnChange(f.name, this.onFilterChanged);
            });

            if (this.filterForm) {
                this.filterForm.unmount();
            }

            this.filterForm = filterForm;
        }
    }

    exportDataViewAsync(filename?: string): Promise<string | undefined> {
        if (!this._url) throw new Error("Missing url");

        const request = this.buildRequest();

        // entire dataset
        request.skip = 0;
        request.top = 0;

        return DataService().exportDataViewAsync(this._url, request, (filename || this.name) + ".csv");
    }

    isFieldVisible(name: string) {
        if (!this._state.fieldsVisibility) return false;
        if (!(name in this._state.fieldsVisibility)) return false;
        return this._state.fieldsVisibility[name];
    }

    toggleFieldVisibility(name: string) {
        if (!this._state.fieldsVisibility) return false;
        if (!(name in this._state.fieldsVisibility)) return false;

        this._state.fieldsVisibility[name] = !this._state.fieldsVisibility[name];
        this.updateFields();
        // this.events.emitModified();

        return true;
    }

    reoderFields(fieldsOrder: string[]) {
        this._state.fieldsOrder = fieldsOrder;
        this.updateFields();
        // this.events.emitModified();

        return true;
    }

    @action
    updateFields() {
        const reset = !this._state.fieldsVisibility || !this._state.fieldsOrder;
        const fieldsVisibility = reset ? {} : toJS(this._state.fieldsVisibility) || {};
        const currFieldsOrder = reset ? [] : toJS(this._state.fieldsOrder) || [];
        const fieldsDict: { [name: string]: IField } = {};
        this._state.data.fields?.forEach((x) => {
            if (!x.name) return;
            // if (this._fixedFields && x.name in this._fixedFields) return;

            if (reset) {
                fieldsVisibility[x.name] = !!x.isVisible;
                currFieldsOrder.push(x.name);
            }

            fieldsDict[x.name] = x;
        });

        const orderedFields: IField[] = [];
        currFieldsOrder.forEach((x) => {
            const field = fieldsDict[x];
            if (!field) return;

            orderedFields.push(field);
        });

        this._state.fieldsVisibility = fieldsVisibility;
        this._state.fieldsOrder = orderedFields.filter((x) => !!x.name).map((x) => x.name as string);
        this._state.fields = orderedFields;
    }

    async fetchAsync(request: IDataViewRequest) {
        request = toJS(request);

        await DataView.fetchRequestAsync(request, this).catch(async (e) => {
            const message = await parseResponseError(e);
            DialogService.error({
                title: "Error",
                message,
            });
        });
    }

    uploadAsync = async (files: File[]) => {
        if (!this._url) throw new Error("Missing url");
        if (files.length !== 1) throw new Error("only support uploading one file at a time");

        // this.reset();

        DataService()
            .dataViewUploadAsync(this._url, files[0])
            .then((response) => {
                if (response) {
                    if (response.success) {
                        DialogService.inform({
                            title: "Import",
                            message: response?.message || "Imported successfully",
                            onPositive: () => this.reloadAsync(),
                        });
                    } else {
                        // response.error may have more context
                        // ...
                        DialogService.error({
                            title: "Import",
                            message: response?.message || "Unspecified error",
                            onClose: () => this.reloadAsync(),
                        });
                    }
                }
            })
            .catch((response) => {
                console.error(response.status);
                DialogService.error({
                    title: "Error",
                    message: "status" in response ? response.status : response,
                    onClose: () => this.reloadAsync(),
                });
            });
    };

    @action
    async loadViewAsync(viewName: string) {
        this._state.isReloading = true;
        this._state.isLoading = true;
        if (!this._url) throw new Error("Missing url");

        const request = {
            skip: this._state.isReloading ? 0 : this.raw.length, // if not reloading, get next the page
            top: this._state.data.pageSize ?? undefined,
            view: viewName,
            breakpoint: this.breakpoint ?? App().breakpoint,
        };

        await this.fetchAsync(request);
    }

    private async loadAsync(breakpoint?: api.ScreenBreakpoint) {
        if (!this._url) throw new Error("Missing url");

        if (!this._state.response || !this._state.response.request) {
            console.error("missing previous request");
            return;
        }

        const request = {
            ...this._state.response.request,
            skip: this._state.isReloading ? 0 : this.raw.length, // if not reloading, get next the page
            top: this._state.data.pageSize ?? undefined,
        };

        if (breakpoint !== undefined) {
            // override breakpoint
            request.breakpoint = breakpoint;
        }

        if (this._state.isReloading) {
            delete request.view;
        } else if (this.viewName) {
            delete request.criteria;
        }

        const visibleFields = this.visibleFields;
        if (!!visibleFields && visibleFields.length > 0) {
            request.fields = visibleFields.filter((x) => !!x.name).map((x) => x.name as string);
        }

        await this.fetchAsync(request);
    }

    async dataFileActionAsync(path: string, actionName?: string, parameters?: any): Promise<string | undefined> {
        const request: IDataFormActionRequest = {
            action: actionName || "download",
            parameters,
            selectedIds: this.selectedIds,
            view: this.viewName,
        };

        return DataService().dataFileActionAsync(path, request, `${action.name}.csv`);
    }

    async partialReloadAsync(ids: string[]): Promise<boolean> {
        if (ids.length > 100) {
            await this.reloadAsync();
            return true;
        }

        this._state.isLoading = true;

        const request: IDataViewRequest = {
            fields: this._state.response?.request?.fields ?? [],
            criteria: [
                {
                    fieldName: "#id",
                    value: ids,
                },
            ],
            breakpoint: App().breakpoint as any,
        };

        const visibleFields = this.visibleFields;
        if (!!visibleFields && visibleFields.length > 0) {
            request.fields = visibleFields.filter((x) => !!x.name).map((x) => x.name as string);
        }

        const url = this._url;
        if (!url) {
            console.error("can not reload");
            this._state.isLoading = false;
            return false;
        }

        const response = await DataService().dataViewAsync(url, request);
        if (!response || !response.result) {
            console.debug("failed request", response);
            this._state.isLoading = false;
            return false;
        }

        const records = this._state.records as IObservableArray;
        const added: any[] = [];
        const deleted: { [id: string]: boolean } = {};
        ids.forEach((x) => (deleted[x] = true));

        runInAction(() => {
            response.result?.forEach((x) => {
                const id = this.id(x);
                delete deleted[id];

                const existing = this.index[id];
                if (existing) {
                    Object.assign(existing, x);
                } else {
                    // console.log("new object", x);
                    this.index[id] = x;
                    added.push(id);
                }
            });

            if (added.length > 0 || Object.keys(deleted).length > 0) {
                const keys = Object.keys(deleted).length > 0 ? records.filter((x) => !deleted[x]) : records;
                added.forEach((x) => keys.push(x));
                records.replace(keys);
            }

            if (Object.keys(deleted).length > 0) {
                // handle selection in case there are deleted
                for (const id of Object.keys(deleted)) {
                    if (this._state.selected[id]) {
                        delete this._state.selected[id];
                        this._state.selectedCount--;
                    }
                }
            }

            this._state.isLoading = false;
        });

        return true;
    }

    /**
     * execute "dataview action" (e.g. dataform action with different payload)
     * - skip abstraction of dataservice
     * @param uri 
     * @returns 
     */
    async runDataViewActionAsync(uri: URI): Promise<IDataFormActionResponse> {
        const path = uri.getPathAndQuery("/DataViewAction");
        const action = uri.searchParams.get("action") ?? 'default';

        const request: api.DataViewActionRequest = {
            criteria: this.calcCriteria(),
            action,
            view: this.viewName,
            selectedIds: [],
            parameters: {},
            orderBy: this.sortedBy,
        };

        const visibleFields = this.visibleFields;
        if (!!visibleFields && visibleFields.length > 0) {
            request.fields = visibleFields.filter((x) => !!x.name).map((x) => x.name as string);
        }

        const client = App().apiClient;
        if (!client) throw "couldn't get api client";

        const result = await client
            .postJson<IDataFormActionResponse>(path, request)
            .catch((error) => {
                console.error("fail to fetch", error);
                const errorResponse: IDataFormActionResponse = {
                    success: false,
                    message: "status" in error ? error.status : error,
                    action: "#cancel",
                };

                return errorResponse;
            });


        return await this.handleActionResultAsync(result);
    }

    async runDataFormActionAsync(path: string, actionOrActionName: Action | string, parameters: object): Promise<IDataFormActionResponse> {
        const action = typeof actionOrActionName === "string" ? actionOrActionName : actionOrActionName.name;
        if (!action) {
            DialogService.error({
                title: "Error",
                message: "Missing action",
            });

            return {
                action: "Missing",
                success: false,
            };
        }

        const request: IDataFormActionRequest = {
            action,
            parameters,
            selectedIds: this.selectedIds,
            view: this.viewName,
        };

        console.log("view::dataFormAction", request);

        var result = await DataService().dataFormActionAsync(path, request);
        return await this.handleActionResultAsync(result);
    }

    private async handleActionResultAsync(result: IDataFormActionResponse): Promise<IDataFormActionResponse> {
        if (!result.success) {
            // show error
            DialogService.error({
                title: "Error",
                message: result.message || "Unknown Error",
            });
            return result;
        }

        if (result.message) {
            DialogService.inform({
                title: "Success",
                message: result.message,
            });
        }

        if (result.ids) {
            await this.partialReloadAsync(result.ids);
        }

        if (!result.nextUrl) {
            // reload
            this.reloadAsync();
        }

        // alert(`handleActionResultAsync: ${result.nextUrl}`);

        return result;
    }

    @action
    reset() {
        // console.log("reset");

        this.index = {};
        this.raw = [];

        this._state.isLastPage = false;
        this._state.isLoading = true;
        this._state.records = [];
        this._state.selected = {};
        this._state.selectedCount = 0;

        if (!this._url) throw new Error("Missing url");
    }

    @action
    reloadAsync(breakpoint?: api.ScreenBreakpoint) {
        this._state.isReloading = true;
        this._state.isLoading = true;
        return this.loadAsync(breakpoint);
    }

    @action
    loadMoreAsync() {
        this._state.isReloading = false;
        this._state.isLoading = true;
        return this.loadAsync();
    }

    @action
    async onColumnsChangedAsync() {
        // if (!this._state.response) {
        //     return;
        // }

        // const loadedFields = this.loadedFields;
        // if (loadedFields) {
        //     const fields: { [name: string]: boolean } = {};
        //     loadedFields.forEach(x => fields[x] = true);
        //     const missing = this.visibleFields.filter(x => !fields[x.name]);
        //     if (missing.length === 0) {
        //         // todo: call api to save new settings
        //         // ...
        //         return;
        //     }
        // }

        await this.reloadAsync();

        // reload all records withoyt clearing
        // this._state.isLastPage = false;
        // this._state.isLoading = true;

        // const request = {
        //     ...this._state.response.request,
        //     skip: 0, // this.raw.length,
        //     top: this.raw.length,
        // };

        // const visibleFields = this.visibleFields;
        // if (!!visibleFields && visibleFields.length > 0) {
        //     request.fields = visibleFields.map(x => x.name);
        // }

        // await this.fetch(request);
    }

    @action
    onDataLoaded(records: object[]) {
        console.log("onloaded", this.raw.length, records.length);

        const key = this._state.data.keyField ?? "_id";

        this.raw = this._state.isReloading ? records : this.raw.concat(records);
        this._state.isLastPage = !this._state.data.pageSize || this._state.data.pageSize < 1 || records.length < this._state.data.pageSize;
        this._state.loadTimestamp = new Date();

        const selected = {};
        records.forEach((row) => {
            const id = row[key];
            this.index[id] = row;
            selected[id] = false;
        });

        if (!this._url) throw new Error("Missing url");

        Object.assign(this._state, {
            fieldsLookup: {},
            selected: observable(selected),
            selectedCount: 0,
        });

        this.updateFields();
        this.initFilterForm();
        this.localSort();
    }

    getFieldLookup(fieldName: string) {
        const lookup = !!this._state?.fieldsLookup && this._state.fieldsLookup[fieldName];
        return lookup;
    }

    private getFilteredIds() {
        if (!this.isFilterableLocally) {
            return this.raw.map((x) => this.id(x));
        }

        // crate local lookups so it can filter locally
        const filterableFields = this.filterableFields;
        const filtered = this._state.filter ? toJS(this._state.filter) : {};
        const filteredFields = Object.keys(filtered);

        // init lookups
        const fieldsLookup: { [fieldName: string]: { [value: string]: ILookupValue } } = {};
        filterableFields.forEach((x) => {
            fieldsLookup[x] = {};
        });

        const unfiltered = filterableFields.filter((x) => !filtered[x]);

        const addToLookup = (field: string, value: any) => {
            const key = value ? value.toString() : "";

            // update lookup
            const fieldLookup = fieldsLookup[field][key];
            if (fieldLookup) {
                fieldLookup.count++;
            } else {
                fieldsLookup[field][key] = {
                    value: value,
                    name: key,
                    count: 1,
                };
            }
        };

        const filterFunc = (record: object) => {
            // apply filters
            var keep = true;
            for (var field of filteredFields) {
                const value = record[field];
                addToLookup(field, value);

                // match one of the values
                let match = false;
                for (var lookup of filtered[field]) {
                    if (value === lookup.value) {
                        match = true;
                        break;
                    }
                }

                if (!match) keep = false;
            }

            if (keep) {
                // add to other not-yet filtered fields
                for (var fieldName of unfiltered) {
                    const value = record[fieldName];
                    addToLookup(fieldName, value);
                }
            }

            return keep;
        };

        const keys: string[] = [];
        this.raw.map((row) => {
            if (filterFunc(row)) {
                var id = row[this._state.data.keyField ?? "_id"];
                keys.push(id);
            }
            return undefined;
        });

        this._state.fieldsLookup = fieldsLookup;

        return keys;
    }

    private setSort(newSortField: string, reverse?: boolean) {
        let { reverseSort, sortBy } = this._state;

        if (reverse === undefined) {
            if (sortBy === newSortField) {
                reverseSort = !reverseSort;
            } else {
                sortBy = newSortField;
            }
        } else {
            sortBy = newSortField;
            reverseSort = reverse;
        }

        if (!this._state.data.filter || this._state.data.filter.indexOf(sortBy) < 0) {
            console.error(`${sortBy} is not sortable`);
            return;
        }

        // reload to sort
        Object.assign(this._state, {
            reverseSort,
            sortBy,
        });
    }

    @action
    sortByField(newSortField: string, reverse?: boolean) {
        this.setSort(newSortField, reverse);
        this.onFilterChanged();
    }

    @action
    setFilters(conditions: api.Condition[], sortBy?: string, reverseSort?: boolean) {
        if (!this.filterForm) return;

        this._batchChanges = true;

        if (sortBy) {
            this.setSort(sortBy, reverseSort);
        }

        const filters : {[field: string]: object} = {};
        conditions.forEach(x=>{
            // TODO: handle operator
            // ...
            filters[x.fieldName!] = x.value;
        });

        this.filterForm.assignValues(filters);

        console.log('load');
        this._batchChanges = false;
        this.onFilterChanged();
    }

    @action
    setFilter(fieldName: string, value: any, operator?: api.Operator, sortBy?: string, reverseSort?: boolean) {
        if (!this.filterForm) return;

        if (sortBy) {
            this.setSort(sortBy, reverseSort);
        }

        this.filterForm.assignValues({ [fieldName]: value });
    }

    localSort() {
        const filteredIds = this.getFilteredIds();

        runInAction(() => {
            Object.assign(this._state, {
                isLoading: false,
                isReloading: false,
            });

            const records = this._state.records as IObservableArray;
            records.replace(filteredIds);
        });
    }

    saveRecordsToCsv(reportName: string) {
        const writer = new CSVWriter();

        var table: any[][] = [];
        table.push(this.fields.map((f) => f.label));
        this.records.forEach((id) => {
            const row = this.fields.filter((x) => !!x.name).map((f) => this.get(id)[f.name as string]);
            table.push(row);
        });

        var csv = writer.export(table);
        var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        var link = document.createElement("a");
        if (link.download !== undefined) {
            // feature detection
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", `${reportName}.csv`);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    // "old" search/filter from grid toolbar
    @action
    searchOrFilter(criteria?: string, args?: any[]) {
        if (!this.isFilterVisible) {
            console.error("view is not configured for search or fitering");
            return;
        }

        this._state.search = criteria;

        if (this.isSearchable) {
            this.onSearch(criteria, args);
            return;
        }

        // filter locally
        const { filter } = this._state.data;
        if (!filter) return;
        if (!criteria || criteria.length < 1) {
            // reset
            this.localSort();
            return;
        }

        const upperCriteria = criteria.toUpperCase();
        const filterFunction = (row: any) => {
            for (var field of filter) {
                var value = row[field];
                if (value && value.toString().toUpperCase().indexOf(upperCriteria) >= 0) {
                    return true;
                }
            }
            return false;
        };

        this.execFilter(filterFunction);
    }

    getFilter(fieldName: string) {
        return this._state.filter ? this._state.filter[fieldName] : undefined;
    }

    @action
    removeFilter(fieldName: string) {
        if (!!this.filterForm) {
            const field = this.filterForm.fieldMap[fieldName];
            this.filterForm.setValue(field, undefined);
            return;
        }

        const filters = this._state.filter ? toJS(this._state.filter) : {};
        delete filters[fieldName];
        this._state.filter = filters;
        this.localSort();
    }

    @action
    toggleLocalFilter(fieldName: string, value: ILookupValue) {
        if (!this.isFilterableLocally) return;

        this._state.isLoading = true;

        const filters = this._state.filter ? toJS(this._state.filter) : {};
        const filter = filters[fieldName] || [];
        const index = filter.findIndex((x) => x.name === value.name);

        if (index < 0) {
            // add
            filter.push(value);
            filters[fieldName] = filter;
            this._state.filter = filters;
        } else {
            // remove
            filter.splice(index, 1);
            filters[fieldName] = filter;

            if (filters[fieldName].length === 0) {
                delete filters[fieldName];
                this._state.filter = Object.keys(filters).length === 0 ? undefined : filters;
            } else {
                this._state.filter = filters;
            }
        }

        this.localSort();
    }

    private execFilter(filter: (record: any) => boolean) {
        const keys: string[] = [];

        this.raw.map((row) => {
            if (filter(row)) {
                var id = row[this._state.data.keyField ?? "_id"];
                keys.push(id);
            }
            return undefined;
        });

        runInAction(() => {
            const records = this._state.records as IObservableArray;
            records.replace(keys);
            Object.assign(this._state, {
                isLoading: false,
            });

            // this.events.emitModified();
        });
    }

    id(row: object) {
        return row[this._state.data.keyField ?? "_id"];
    }

    get(key: string): object {
        return this.index[key];
    }

    isSelected(row: object): boolean {
        let id = this.id(row);
        return this._state.selected[id];
    }

    @action
    select(row: object): boolean {
        let id = this.id(row);

        if (this.isSelectable) {
            let selected = this._state.selected[id];
            this._state.selected[id] = !selected;
            this._state.selectedCount += selected ? -1 : 1; // object.keys(this._state.selected).length?
            return true;
        }

        return false;
    }

    @action
    selectAll(select: boolean) {
        if (!this.isSelectable) return;
        if (!select) {
            this._state.selected = {};
            this._state.selectedCount = 0;
            return;
        }

        this._state.selectedCount = this._state.records.length;
        this._state.records.forEach((id) => {
            this._state.selected[id] = true;
        });
    }

    @action
    async showDetailAsync(row: object, field?: string | null, openNewTab?: boolean): Promise<boolean> {
        if (!this._state.data.detail?.page) return false;

        const id = this.id(row);
        var path = this._state.data.detail.page;
        if (path.indexOf("{{id}}") > 0) {
            // simple template parameter replacement
            path = path.replace("{{id}}", id);
        } else if (URI.isUri(path)) {
            // hack to complement dataform edit url
            path += path.indexOf("?") > 0 ? `&id=${id}` : `?id=${id}`;
        } else {
            // hack for old custom pages? (e.g. Lead=id)
            path += `=${id}`;
        }

        if (openNewTab) {
            // TODO: allow page to opt in/opt out
            // ...
            if (await App().openInNewTab(path)) return true;
        }

        var record = {
            id,
            ...toJS(row),
        };

        const criteria = this._state.response?.request?.criteria ? toJS(this._state.response.request.criteria) : undefined;

        const detailArgs: DetailViewArgs = {
            id,
            field,
            value: field ? row[field] : undefined,
            criteria,
        };

        if (await App().selectPageAsync(path, this.name, record, detailArgs)) return true;

        console.error("invalid detail configuration", this._state.data.detail);
        return false;
    }

    bindAction(action: string, funct: Function): DataView {
        Default.actions.set(action, funct, `dataView.${this.name}`);
        return this;
    }

    private onSearch = (value?: string, args?: any[]) => {
        if (this._searchTimeout) {
            window.clearTimeout(this._searchTimeout);
        }

        this._searchTimeout = window.setTimeout(this.runSearch(value, args), 200);

        return true;
    };

    public async runSingleObjectActionAsync(action: api.ActionMenuItem, selectedObject: object): Promise<boolean> {
        console.log('action', action);

        if (typeof (action.action) !== "string") return false;

        const id = this.id(selectedObject);
        let actionUrl = action.action;

        const subst = action.action.match(/({{[^}]+}})/g) as string[];
        if (subst) {
            for (const s of subst) {
                // if (s === "{{value}}") url = url.replace(s, value);
                // else 
                if (s === "{{id}}") actionUrl = actionUrl.replace(s, id);
                else {
                    const fieldName = s.substring(2, s.length - 2);
                    if (fieldName in selectedObject) {
                        actionUrl = actionUrl.replace(s, selectedObject[fieldName]);
                    }
                }
            }
        }

        // // if (e.ctrlKey || e.metaKey) {
        // App().selectPage(url, { id });
        // // }

        if (!URI.isUri(actionUrl)) return false;

        // url
        const uri = new URI(actionUrl);
        switch (uri.scheme) {
            case "action:": {
                const path = uri.getPathAndQuery();
                // TODO: async
                // ...
                const result = await this.runDataFormActionAsync(path, action, selectedObject);
                if (result.nextUrl) {
                    // process next url
                    await this.executeActionAsync({ name: action.name, action: result.nextUrl });
                }
                return true;
            }
        }

        return this.handleUrlAsync(actionUrl, id, action.name!);
    }

    private async executeDialogActionAsync(path: string, action: string, values: object) {
        const result = await this.runDataFormActionAsync(path, action, values);
        if (!result.success && !result.nextUrl) {
            // failed, prevent closing window
            // TODO: show error
            // ...
            // alert(`Failed to execute: ${action}`);
            return result;
        }

        // alert(`executeDialogActionAsync: ${action} => ${result.nextUrl}`);

        await App().closeDataFormDialogAsync(true);

        if (result.nextUrl) {
            // process next url
            await this.executeActionAsync({ name: action, action: result.nextUrl });
        }

        return result;
    }

    private replacePlaceholders(url: string, values: object): string {
        let resolved = url;

        const subst = url.match(/({{[^}]+}})/g) as string[];
        if (!subst) return url;

        for (const s of subst) {
            const fieldName = s.substring(2, s.length - 2);
            if (fieldName in values) {
                resolved = resolved.replace(s, values[fieldName]);
            }
        }
        return resolved;
    }

    public onDialogActionAsync = async (dialog: IFormDialog, action?: Action, lastResult?: IDataFormActionResponse) => {
        if (!action) {
            // no action => simple close: defer
            return false;
        }

        const actionStr = this.replacePlaceholders((typeof action.action === "string" ? action.action : null) ?? action.name!, dialog.form.values);

        if (actionStr?.startsWith("#")) {
            // local actions
            switch (action.name) {
                case "#reload":
                    await this.reloadAsync();
                    await App().closeDataFormDialogAsync();
                    return true;
                default:
                    return false;
            }
        }

        if (URI.isUri(actionStr)) {
            // url
            const uri = new URI(actionStr!);
            switch (uri.scheme) {
                case "action:": {
                    const pathAndQuery = uri.getPathAndQuery();
                    const result = await this.executeDialogActionAsync(pathAndQuery, action.name ?? 'default', dialog.form.values);
                    if (!result?.success && result?.message) {
                        dialog.form.error = result.message;
                    } else {
                        dialog.form.error = undefined;
                    }
                    return true;
                }
            }

            // close all dialogs to make sure that the data in the current dialog 
            // is not outdated after the action
            // TODO: there may be some compromise 
            await App().closeDataFormDialogAsync(true);

            await this.handleUrlAsync(actionStr!);

            return true;
        }

        if (dialog.form.url && actionStr) {
            // form action
            const result = await this.executeDialogActionAsync(dialog.form.url, actionStr, dialog.form.values);
            if (!result?.success && result?.message) {
                dialog.form.error = result.message;
            } else {
                dialog.form.error = undefined;
            }

            // const result = await this.view.dataFormAction(dialog.form.url, action, values);
            // if (!result.success && !result.nextUrl) {
            //     // failed, prevent closing window
            //     return true;
            // }

            // App().closeDataFormDialog(true);

            // if (result.nextUrl) {
            //     // process next url
            //     return this.go({ name: action.name, action: result.nextUrl });
            // }
            return true;
        }

        // local function?
        const promise = dialog.form.executeAsync(action);
        await App().closeDataFormDialogAsync(
            true,
            {
                name: "#close"
            },
            {
                action: action?.name ?? "",
                success: !!promise,
            });

        return true;
    };

    public async handleUrlAsync(actionUrl: string, id?: string, actionName?: string): Promise<boolean> {
        const url = new URI(actionUrl);
        // alert(`handleUrlAsync: ${actionUrl} => ${url.scheme}`)
        switch (url.scheme) {
            case "dataform:": {
                const action = url.searchParams.get("action");
                if (action) {
                    const params: { [name: string]: any } = {};
                    url.searchParams.forEach((value, name) => (params[name] = value));
                    delete params[action];
                    await this.runDataFormActionAsync(url.path, action, params);
                    return true;
                }

                const selectedId = this.selectedCount === 1 ? this.selectedIds[0] : id;
                const filter = this.calcCriteria().filter(x => !x.operator || x.operator === api.Operator.Eq);
                const query: { [key: string]: any } = {};
                filter.forEach(x => { if (x.fieldName) { query[x.fieldName] = x.value } });
                url.searchParams.forEach((value, name) => (query[name] = value));
                await App().dataFormAsync(url.path, selectedId, this.onDialogActionAsync, {
                    ...query,
                    ...this.fixedFields
                });
                return true;
            }

            case "action:": {
                const result = await this.runDataViewActionAsync(url)
                if (result.nextUrl) {
                    // process next url
                    await this.executeActionAsync({ name: 'nextUrl', action: result.nextUrl });
                }
                return true;
            }

            case "datafile:":
                await this.dataFileActionAsync(url.path, actionName);
                return true;

            case "page:":
            case "datagrid:":
                if (!!id) {
                    await App().selectPageAsync(actionUrl, { id });
                } else {
                    await App().selectPageAsync(actionUrl);
                }
                return true;

            case "waze:":
            case "mailto:":
            case "tel:":
            case "openphone:":
            case "http:":
            case "https:":
            case "sms:":
                App().launchInNewTab(actionUrl);
                return true;

            default:
                alert(`protocol not implemented: ${url.scheme}`);
                break;
        }

        return false;
    }

    public async executeActionAsync(action: Action, id?: string): Promise<boolean> {
        if (typeof action.action !== "string") {
            alert('executeActionAsync: not string');
            return false;
        }

        if (!URI.isUri(action.action)) {
            // alert(`executeActionAsync: not uri, ${action.action}`);
            switch (action.action) {
                case "#export":
                    this.saveRecordsToCsv(this.name);
                    return true;

                case "#csv":
                    await this.exportDataViewAsync(action.name!);
                    return true;

                case "#reload":
                    await this.reloadAsync();
                    return true;

                case "#upload":
                    // deprecated?
                    this.showUploadForm(action);
                    return true;

                case "#save":
                    await this.showSaveFormAsync(action);
                    return true;

                case "#cancel":
                    return true;

                default:
                    break;
            }

            if (action.action.startsWith("#view=")) {
                const viewName = decodeURIComponent(action.action.substring("#view=".length));
                console.log("load view", viewName);
                await this.loadViewAsync(viewName);
                return true;
            }

            return false;
        }

        var actionPath = action.action;
        if (actionPath.indexOf("{{id}}") > 0 && id) {
            actionPath = actionPath.replace("{{id}}", id);
        }

        // alert(`executeActionAsync: ${action.action} = ${actionPath}`);

        return await this.handleUrlAsync(actionPath, id, action.name!);
    }

    private async showSaveFormAsync(action: Action) {
        const url = `${this.baseUrl}/Dataview/Save?BreakPoint=${this.breakpoint ?? App().breakpoint}`;
        const form = await App().loadFormAsync(url);
        if (!form) return;

        const saveView = async () => {
            console.log("save view");
            const formDialog = App().formDialog;
            if (!formDialog?.form) return;

            const input = formDialog.form.values;
            const response = await this.saveViewAsync(input);
            if (response.success) {
                if (response.action) {
                    await this.executeActionAsync({ name: "viewSaved", action: response.action });
                }
            } else {
                alert("Error saving view");
            }

            await App().closeDataFormDialogAsync(true);
        };

        form.bindAction("#save", saveView);

        App().pushFormDialog({
            form: Form.create(form, url),
        });
    }

    private showUploadForm(action: Action) {
        const uploadFile = () => {
            const formDialog = App().formDialog;
            if (!formDialog?.form) return;

            const file = formDialog.form.values["file"];
            if (file instanceof File) {
                console.log("upload", file.name);
                this.uploadAsync([file]);
            }
        };

        // TODO: replace with api call to get form
        // ...
        const form: IForm = {
            name: "Upload",
            title: action.label || action.name,
            fields: [
                {
                    t: "FileField",
                    type: "file",
                    name: "file",
                },
            ],
            actions: [
                {
                    name: "Upload",
                    action: uploadFile,
                } as any as api.FormAction,
            ],
            isReadOnly: false,
        };

        App().pushFormDialog({
            form: Form.create(form, null),
        });
    }
}
